import "./index.css"
import clock from "../../assets/images/clock.svg"
import { useEffect, useState } from "react"
import axios from "axios"
import Cookies from "js-cookie"
import { IoCloseCircleOutline } from "react-icons/io5"
import toast, { Toaster } from "react-hot-toast"
import ZeroMessages from "../../utils/ZeroMessage/zeroMessage.json"
import ZeroData from "../../components/ZeroData"
import { MenteeSessionCancel } from "../../pages/MenteeBooking"
import JoinBooking from "../../components/JoinSession"
import { convertToAMPM } from "../../utils/HelperFunctions"
import { useContext } from "react"
import { AuthContext } from "../../contexts/AuthContext"


interface sessionData {
    session_id: number | null;
    mentee: string;
    role: string;
    organisation: string;
    time: string;
    meet_url: string | undefined;
    date: string;
    status: string;
    meet_type: string;
    reason:string;
    profile_pic:string;
}

const Bookings : React.FC = () =>{
    const [tabType, changeBookingsTab] = useState<string>("Upcoming");

    const [sessionDatas, setSessionDatas] = useState<sessionData[]>([]);
    const [filteredSessionDatas, setFilteredSessionDatas] = useState<sessionData[]>([]);
    const [popUp, setPopUp] = useState<boolean>(false);
    const [loader, SetLoader] = useState<boolean>(false);
    const [popUpSessionId, setPopUpSessionId] = useState<number | null>(null);
    const [startedSessionId, setStartedSessionId] = useState<number | null>(null);
    const [sessionCancelPopUp, setSessionCancelPopUp] = useState<boolean>(false);
    const [cancelSessionId, setCancelSessionId] = useState<number | null>(null);
    const [joinSession, setJoinSession] = useState<boolean>(false);
    const [startSessionData, setStartSessionData] = useState<any>(null);
    const [popupMode, setPopupMode] = useState<string>("");
    const [completeSessionPopup, setCompleteSessionPopup] = useState<boolean>(false);
    const { setSessionCount} = useContext(AuthContext);

    useEffect(()=>{
        const fetchData = async() =>{
            try{
                const response = await axios.get(`${process.env.REACT_APP_GROWBINAR_BACKEND_URL}/sessions/upcoming_sessions/`, {
                    headers: {
                        Authorization: `Bearer ${Cookies.get("GB_ACCESS_TOKEN")}`,
                        "Content-Type": "application/json",
                    },
                });
                setSessionDatas(response.data.data);
            }
            catch(err){
                console.log(err);
            }
        }
        fetchData();
    },[])

    useEffect(()=>{
        if(sessionDatas.length){
            setFilteredSessionDatas(sessionDatas.filter((sessionData)=>sessionData.status === 'Upcoming'));
        }
    },[sessionDatas])

    useEffect(()=>{
        setFilteredSessionDatas(sessionDatas.filter((sessionData)=>sessionData.status === tabType));
    },[tabType, sessionDatas])

    useEffect(()=>{
        setStartSessionData(() => filteredSessionDatas.find((sessionData) => sessionData.session_id === startedSessionId));   
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[startedSessionId])





    const convertDate = (date: string) =>{
        const months = ["January","February","March","April","May","June","July","August","September","October","November","December"];
        const dateArr = date.split("-");
        return `${months[parseInt(dateArr[1])-1]} ${dateArr[2]}`;
    }

    const AcceptSession = async()=>{
        try{
            SetLoader(true)
            const response = await axios.post(`${process.env.REACT_APP_GROWBINAR_BACKEND_URL}/sessions/book_session/`,{
                session_id: popUpSessionId
            }, {
                headers: {
                    Authorization: `Bearer ${Cookies.get("GB_ACCESS_TOKEN")}`,
                    "Content-Type": "application/json",
                },
            })
            SetLoader(false)
            if(response.status === 200){
                setPopUp(false);
                toast("Session Accepted")
                setSessionCount((prev:any)=>({
                    ...prev,
                    pendingCount: prev.pendingCount - 1,
                    upcommingCount: prev.upcommingCount + 1
                }))
                setSessionDatas((prevData)=>{
                    return prevData.map((sessionData)=>{
                        if(sessionData.session_id === startedSessionId){
                            sessionData.status = "Upcoming";
                            sessionData.meet_url = response.data.data.link;
                        }
                        return sessionData;
                    })
                })

                filteredSessionDatas.map((sessionData)=>{
                    if(sessionData.session_id === popUpSessionId){
                        sessionData.status = "Upcoming";
                        sessionData.meet_url = response.data.data.link;
                    }
                    return sessionData;
                })
            }
        }
        catch(err){
            SetLoader(false);
            console.log(err);
            toast.error("Error in accepting session");
        }
    }

    const completeSession = async() =>{
        try{
            SetLoader(true);
            const response = await axios.post(`${process.env.REACT_APP_GROWBINAR_BACKEND_URL}/sessions/session_complete/`,{
                id: startedSessionId
            },{
                headers: {
                    Authorization: `Bearer ${Cookies.get("GB_ACCESS_TOKEN")}`,
                    "Content-Type": "application/json",
                },
            })
            if(response.status === 200){
                toast("Session Completed")
                setStartedSessionId(null);
                setSessionDatas((prevData)=>{
                    return prevData.map((sessionData)=>{
                        if(sessionData.session_id === startedSessionId){
                            sessionData.status = "Completed";
                        }
                        return sessionData;
                    })
                })
            }
            else{
                toast.error("Could'nt update completion status")
            }
            SetLoader(false);
            setCompleteSessionPopup(false);
        }
        catch(err){
            console.log(err);
        }
    }

    const filterMentorSessions = (state: string, session_id?: number | null) => {

        if (!filteredSessionDatas) return;
        if (state === "deletion" && session_id !== null) {
            setFilteredSessionDatas((prev: any) => {
                return prev.filter((session: any) => session.session_id !== Number(session_id))
            })
            setSessionDatas((prev : any)=>{
                return prev.filter((prevData : any) => prevData.session_id !== Number(session_id))
            })
            return;
        }
    }

    const handleSessionCancellation = (session_id: number) => {
        setCancelSessionId(session_id)
        setSessionCancelPopUp(true)
    }

    const handleCompleteSession = (session_id: number | null) => {
        setCompleteSessionPopup(true)
    }

    const handleStartSession = (session_id : number) => {
        setStartSessionData(() => filteredSessionDatas?.find((sessionData : any) => sessionData.session_id === session_id));   
        setPopupMode("join")
        setJoinSession(true)
    }



    return(
        <div>
            <Toaster/>
            {
                sessionCancelPopUp && (
                    <MenteeSessionCancel setSessionCancelPopUp={setSessionCancelPopUp} session_id={cancelSessionId} filterRemoval={filterMentorSessions} tabType={tabType} />
                )
            }
            {
                joinSession && (
                    <JoinBooking setPopUp={setJoinSession} sessionDetails={startSessionData} setStartedSessionId = {setStartedSessionId} popupMode = {popupMode} />
                )
            }
            {
                completeSessionPopup && (
                    //popup for completing session
                    <div className="fixed inset-0 w-full h-full bg-black flex bg-opacity-80 items-center justify-center z-20" onClick={() =>{
                                setCompleteSessionPopup(false)
                            }}>
                        <div className="!bg-white rounded-xl p-[16px] text-black relative flex flex-col gap-[16px]" onClick={(e)=> e.stopPropagation()}>
                            <button className="absolute top-[16px] right-[16px]" onClick={() =>{
                                setCompleteSessionPopup(false)
                            }}>
                                <IoCloseCircleOutline className="text-[24px] hover:text-red-600" />
                            </button>
                            <div className="flex flex-col gap-[8px] mt-[16px]">
                                <h1 className="text-xl font-medium">Complete Session</h1>
                                <p className="text-gray-600 text-lg font-normal">Are you sure you want to complete the session?</p>
                            </div>
                            {
                                loader === true ? (
                                    <div>
                                        <button className="w-full p-[10px] bg-txtPurple text-white text-2xl font-semibold rounded-x flex items-center justify-center rounded-xl"> <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24">
                                            <path fill="currentColor" d="M12,4a8,8,0,0,1,7.89,6.7A1.53,1.53,0,0,0,21.38,12h0a1.5,1.5,0,0,0,1.48-1.75,11,11,0,0,0-21.72,0A1.5,1.5,0,0,0,2.62,12h0a1.53,1.53,0,0,0,1.49-1.3A8,8,0,0,1,12,4Z">
                                                <animateTransform attributeName="transform" dur="0.75s" repeatCount="indefinite" type="rotate" values="0 12 12;360 12 12" />
                                            </path>
                                        </svg></button>
                                    </div>
                                ) : (
                                    <div>
                                        <button className="w-full p-[10px] bg-txtPurple text-white text-2xl font-semibold rounded-xl " onClick={()=>completeSession()}>Confirm</button>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                )
            }
            <div  className="p-4">
                <p className=" text-left text-[#263238] text-[32px] p-2 font-bold not-italic leading-9">
                    Discover Bookings
                    
                </p>
                <div className="">
                    <p className="text-[#464646] text-left text-[18px] p-2 leading-9 font-medium not-italic">
                        The session timings are following your local timezone <span className="font-bold">Asia/Calcutta </span><span className="text-gbpurple">change</span></p>
                </div>
                
                {/*bookings tab -- 1:1 , completed, request pending */}
                <div className="flex justify-between sm:justify-start flex-row sm:gap-[25px]">
                    <button className={`p-2 text-[#130F26] text-[16px] text-center font-medium not-italic leading-9 ${tabType === 'Upcoming' ? 'border-black border-b-2 ' : 'border-transparent'} focus: translate-all`}  onClick={()=>{changeBookingsTab('Upcoming')}}>1:1 Booking</button>
                    <button className={`p-2 text-[#130F26] text-[16px] text-center font-medium not-italic leading-9 ${tabType === 'Completed' ? 'border-black border-b-2 ' : 'border-transparent'} focus: translate-all`}  onClick={()=>{changeBookingsTab('Completed')}}>Mentorship Logs</button>
                    <button className={`relative p-2 text-[#130F26] text-[16px] text-center font-medium not-italic leading-9 ${tabType === 'Pending Request' ? 'border-black border-b-2 ' : 'border-transparent'} focus: translate-all`} onClick={() => { changeBookingsTab('Pending Request') }}>
                        Awaiting Approval
                        <span className={`${ sessionDatas.filter((sess,index)=>sess.status === "Pending Request").length !== 0 ? "absolute" : "hidden"} top-2 -right-1 flex h-3 w-3`}>
                            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-txtPurple opacity-75"></span>
                            <span className="relative inline-flex rounded-full h-3 w-3 bg-txtPurple"></span>
                        </span>
                    </button>
                </div>

                {/*mentee-profile-info */}
                {
                    filteredSessionDatas && filteredSessionDatas.length > 0 ?
                        filteredSessionDatas.map((sessionData,value)=>(
                            <div className={`bg-gray-100 lg:w-[800px] md:w-[700px] sm:[600px] w-full border-[0.5px] rounded-lg mt-[20px] flex-shrink-0 p-[8px] ${tabType === "Completed" ? "opacity-70 border-gbpurple" : "opacity-100 border-[#D9D9D9]"}`} key={sessionData.session_id} id="value">
                            <div className="flex flex-col details-with-main-container">
                                    
                                    <div className="flex  flex-shrink-0 gap-3 p-2 w-full">   
                                        <img className=" sm:h-[137px] h-[120px] flex-shrink-0 rounded" src={sessionData.profile_pic} alt="mentee"/>
                                        <div className="flex justify-between flex-col sm:flex-row w-full">
                                            <div className="flex flex-col gap-[12px] w-full">
                                                <p className="text-[#455A64] text-[15px] w-full font-medium leading-6 not-italic">
                                                    Mentorship Session with <br></br>
                                                    <span className="text-txtPurple">{sessionData.mentee}</span>
                                                </p>
                                                <p className="text-[#636B78] text-[15px] font-medium leading-6 not-italic ">{sessionData.organisation} <span className="text-[#A1A6AD]">at </span>{sessionData.role} </p>
                                                <div className="inline-flex gap-2 items-center justify-normal mt-auto">
                                                    <img className="h-[14px] w-[14px] flex-shrink-0" src={clock} alt="clock"/>
                                                    <p className="text-[#464646] text-[13px] font-medium leading-5 not-italic">{convertDate(sessionData.date)} on  {convertToAMPM(sessionData.time)}</p>
                                                </div>
                                                <div>
                                                    <div className={`border-txtGrey p-[12px] whitespace-nowrap border bg-[#FBFBFE]  rounded-[8px] inline-block`} >
                                                        {sessionData.reason}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="flex gap-2" >
                                    {
                                        tabType === 'Upcoming'?
                                        (
                                            <>
                                                <button className="h-[50px] w-[205px] p-[10px] text-[#F9F9FA] bg-gbpurple hover:opacity-80 text-[18px] text-center font-medium not-italic leading-4 rounded-lg m-[10px] duration-100 ease-in"
                                                onClick={
                                                    ()=>{
                                                        handleStartSession(sessionData.session_id as number) 
                                                        setPopupMode("join")
                                                        // window.open(sessionData?.meet_url)
                                                    }
                                                }
                                                >
                                                    {(startedSessionId === sessionData.session_id) ? "Rejoin" : "Start"}
                                                </button>
                                                {
                                                    (startedSessionId === sessionData.session_id) && (
                                                            <button className="h-[50px] w-[205px] p-[10px] text-[#F6291C] bg-[#F9F9FA] hover:bg-gray-100 duration-100 ease-in text-[18px] text-center font-medium not-italic leading-4 border border-[#D1D4D6] rounded-lg my-[10px]"
                                                        onClick={()=>handleCompleteSession(startedSessionId)}
                                                        >Completed</button>
                                                    )
                                                }
                                                    <button className="h-[50px] w-[205px] p-[10px] text-[#F6291C] bg-[#F9F9FA] hover:bg-gray-100 duration-100 ease-in text-[18px] text-center font-medium not-italic leading-4 border border-[#D1D4D6] rounded-lg my-[10px]" onClick={() => handleSessionCancellation(Number(sessionData.session_id))}>
                                                        Cancel
                                                    </button>  
                                            </>
                                        )
                                    :
                                        tabType === 'Pending Request'?
                                        (    
                                            <>
                                                <button className="h-[50px] w-[205px] p-[10px] bg-txtPurple hover:opacity-80 duration-100 ease-in text-white text-[18px] text-center font-medium not-italic leading-4 rounded-lg border border-[#DBDDDF] m-[10px]" onClick={()=>{
                                                    setPopUp(true);
                                                    setPopUpSessionId(sessionData.session_id)
                                                }}>Accept Request</button>
                                                        <button className="h-[50px] w-[205px] p-[10px] text-[#F6291C] bg-[#F9F9FA] hover:bg-gray-100 text-[18px] duration-100 text-center font-medium not-italic leading-4 border border-[#D1D4D6] rounded-lg my-[10px]" onClick={() => handleSessionCancellation(Number(sessionData.session_id))}>Cancel</button>  
                                            </>
                                        )
                                    :
                                        ''  
                                    }
                                    </div>
                                </div>
                        ))
                    :
                    <ZeroData one={ZeroMessages[tabType as keyof typeof ZeroMessages].one} two={ZeroMessages[tabType as keyof typeof ZeroMessages].two}/>

                }
            </div>
            {popUp && (
                    <div className="fixed inset-0 w-full h-full bg-black flex bg-opacity-80 items-center justify-center z-20"onClick={() =>{
                                setPopUp(false)
                                setPopUpSessionId(null);
                            }}>
                        <div className="!bg-white rounded-xl p-[16px] text-black relative flex flex-col gap-[16px]" onClick={(e)=>e.stopPropagation()}>
                            <button className="absolute top-[16px] right-[16px]" onClick={() =>{
                                setPopUp(false)
                                setPopUpSessionId(null);
                            }}>
                                <IoCloseCircleOutline className="text-[24px] hover:text-red-600" />
                            </button>
                            <div className="flex flex-col gap-[8px] mt-[16px]">
                                <h1 className="text-xl font-medium">Accept Session Booking</h1>
                                <p className="text-gray-600 text-lg font-normal">Are you sure you want to Accept the session?</p>
                            </div>
                            {
                                loader === true ? (
                                    <div>
                                        <button className="w-full p-[10px] bg-txtPurple text-white text-2xl font-semibold rounded-x flex items-center justify-center rounded-xl"> <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24">
                                            <path fill="currentColor" d="M12,4a8,8,0,0,1,7.89,6.7A1.53,1.53,0,0,0,21.38,12h0a1.5,1.5,0,0,0,1.48-1.75,11,11,0,0,0-21.72,0A1.5,1.5,0,0,0,2.62,12h0a1.53,1.53,0,0,0,1.49-1.3A8,8,0,0,1,12,4Z">
                                                <animateTransform attributeName="transform" dur="0.75s" repeatCount="indefinite" type="rotate" values="0 12 12;360 12 12" />
                                            </path>
                                        </svg></button>
                                    </div>
                                ) : (
                                    <div>
                                        <button className="w-full p-[10px] bg-txtPurple text-white text-2xl font-semibold rounded-xl" onClick={()=>AcceptSession()}>Confirm</button>
                                    </div>
                                )
                            }

                        </div>
                    </div>
                )}
        </div>  
    );
}

export default Bookings;