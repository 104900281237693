import React, { useContext, useEffect } from "react";
import "../DashboardLayout/index.css";
import SideNavBar from "../../components/SideNavBar";
import axios from "axios";
import Cookies from "js-cookie";
import { AuthContext } from "../../contexts/AuthContext";

interface MyProps {
  Head: React.ComponentType;
  showMentorProfile?: boolean;
  PageComponent: React.ComponentType;
  BackHeader: React.ReactNode; // Added return type annotation
}
const Layout: React.FC<MyProps> = ({ Head, showMentorProfile, PageComponent, BackHeader }: MyProps) => {

  const { authed, setSessionCount, setUserProfile} = useContext(AuthContext)
  const userId = localStorage.getItem("GB_USER_ID");

  const getUserSessionCount = async () =>{
    const uri = `${process.env.REACT_APP_GROWBINAR_BACKEND_URL}/session_count/`

    try{
      const response = await axios.get(uri, {
       headers:{
           "Authorization": `Bearer ${Cookies.get("GB_ACCESS_TOKEN")}`
       }
      })
       
       setSessionCount({
        pendingCount:response.data.pendingCount,
        upcommingCount:response.data.upcommingCount,
       })

    }catch(err : any){
      console.log(err)
    }
  }

  const getUserProfile = async () =>{
    const mentor_uri = `${process.env.REACT_APP_GROWBINAR_BACKEND_URL}/profile/mentor_details/?id=${userId}`
    const mentee_uri = `${process.env.REACT_APP_GROWBINAR_BACKEND_URL}/profile/mentees/?id=${userId}/`

    const uri = authed.role === "mentor" ? mentor_uri : mentee_uri

    try{
      const response = await axios.get(uri)
      setUserProfile(response.data.data)
    }catch(err : any){
      console.log(err)
    }
  } 

  useEffect(()=>{
    getUserSessionCount()
    getUserProfile()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    <div className="flex bg-white  h-screen scroll-smooth no-scrollbar overflow-y-auto">
      {/* Sidebar */}

      {/* Main Content Area */}
      <div className="main-wrapper">
        <header className="top-0 z-10 w-full h-[100px] md:sticky block md:bg-white bg-[#DDDAFF]">
          <div className="md:block hidden">
            {Head && <Head />}
          </div>
          <div className="md:hidden block">
            {BackHeader}
          </div>
        </header>
        <div className="flex flex-row">
          <div className="flex  bg-white z-10
                            md:relative md:bottom-auto md:w-auto 
                            fixed  bottom-0 w-full
                            ">
          {/* role can be null, only render sidenar after role is verified */}
            { authed.role && (
              <SideNavBar role={authed.role}/>
            )}
          </div>
          {/* Page Content */}
          <div className=" overflow-x-hidden flex-1 md:h-[calc(100vh-80px)] overflow-y-auto no-scrollbar pb-[80px]">
            <PageComponent />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
