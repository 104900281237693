//day form date ex: 01/01/2024
function getDayFromDate(date: string) {
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const d = new Date(date);
    return days[d.getDay()];
}

// 01/01/2024 to jan 1
function getMonthDateFromDate(date: string) {
    if (!date) return null;
    const d = new Date(date);
    const month = d.toLocaleString("default", { month: "short" });
    return `${month} ${d.getDate()}`;
}

function convertToAMPM(time: string) {
    // Split the time into hours and minutes
    if (!time) return null;
    const [hours, minutes] = time.split(':');

    // Create a new Date object and set the hours and minutes
    const date = new Date();
    date.setHours(Number(hours));
    date.setMinutes(Number(minutes));

    // Format the time in 12-hour format
    const ampmTime = date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });

    return ampmTime;
}

export {getDayFromDate , getMonthDateFromDate, convertToAMPM}