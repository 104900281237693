import { createContext } from "react"

interface AuthedType{
    status:number;
    authed:boolean;
    verified:boolean;
    role:string;
}

interface AuthContextType {
    authed:AuthedType;
    setAuthed: Function;
    userProfile?:any;
    setUserProfile: Function;
    sessionCount:any;
    setSessionCount: Function;
}

export const AuthContext = createContext({} as AuthContextType)