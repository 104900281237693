import { useEffect, useState } from "react";
import "./index.css";
import { IoCloseCircleOutline } from "react-icons/io5";
import axios from "axios";
import toast, {Toaster} from "react-hot-toast";
import { getDayFromDate, getMonthDateFromDate , convertToAMPM } from "../../utils/HelperFunctions";
import ConfirmBooking from "../ConfirmBooking";
import Cookies from "js-cookie";

interface avaliableDateTimeObj {
  to: string;
  from: string;
  date: string;
}

export interface AvailableSessionProps {
  mentor_id : string | undefined;
  data: avaliableDateTimeObj[];
  view_state:string;
  userDetails? :any;
}

type SessionList = avaliableDateTimeObj[];



function mapSessionsByDate(sessionList: SessionList) {
  const mappedSessions: { [date: string]: { sessions: avaliableDateTimeObj[], count: number } } = {};

  sessionList.forEach(session => {
    if (!mappedSessions[session.date]) {
      mappedSessions[session.date] = { sessions: [], count: 0 };
    }

    mappedSessions[session.date].sessions.push(session);
    mappedSessions[session.date].count += 1;
  });

  return mappedSessions;
}

export default function AvailableSession({ data, mentor_id, view_state, userDetails }: AvailableSessionProps) {
  // const [selectedDateSlot, setSelectedDateSlot] = useState<slotDetails>();
  const [mappedDateSlots, setMappedDateSlots] = useState<{ [date: string]: { sessions: avaliableDateTimeObj[]; count: number; }; } | null>(null);
  const [selectedDateSlot, setSelectedDateSlot] = useState<string | null>(null);
  const [selectedTimeIndex, setSelectedTimeIndex] = useState<number | null>(null);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<any | null>(null);
  const [bookingPopup, setBookingPopup] = useState<boolean>(false);
  const [sessionCreationLoader,setSessionCreationLoader] = useState<boolean>(false);
  const [deleteSessionPopup, setDeleteSessionPopup] = useState<boolean>(false);


  useEffect(() => {
    setMappedDateSlots(() => {
      return mapSessionsByDate(data)
    })
  }, [data]);

  const handleDeleteSession = async () => {
    try {
      setSessionCreationLoader(true);
      const response = await axios.post(
        `${process.env.REACT_APP_GROWBINAR_BACKEND_URL}/sessions/delete_available_session/`,
        {
          session: selectedTimeSlot,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("GB_ACCESS_TOKEN")}`,
          },
        }
      );
  
      setMappedDateSlots((prev) => {
        if (prev) {
          const date = selectedDateSlot as string;
          const updatedSessions = prev[date].sessions.filter(
            (session) => session !== selectedTimeSlot
          );
  
          if (updatedSessions.length > 0) {
            setSelectedDateSlot(null);
            setSelectedTimeSlot(null);
            return {
              ...prev,
              [date]: {
                sessions: updatedSessions,
                count: updatedSessions.length,
              },
            };
          } else {
            setSelectedDateSlot(null);
            setSelectedTimeSlot(null);
            const { [date]: removed, ...rest } = prev;
            if (Object.keys(rest).length === 0) {
              return null;
            }
            return rest;
          }
        }
        return null;
      });
  
      if (response.status === 200) {
        toast("Deleted successfully");
      }
    } catch (err) {
      console.log(err);
    }
    setSessionCreationLoader(false);
    setDeleteSessionPopup(false);
  };

  const handleDateSlotClick = (date: string) => {
    if (selectedDateSlot === date) {
      setSelectedDateSlot(null);
    } else {
      setSelectedDateSlot(date);
    }
    setSelectedTimeIndex(null);
    setSelectedTimeSlot(null);
  }

  const handleTimeSlotClick = (index: number) => {
    if (selectedTimeIndex === index) {
      setSelectedTimeIndex(null);
      setSelectedTimeSlot(null);
    } else {
      setSelectedTimeIndex(index);
      setSelectedTimeSlot(mappedDateSlots![selectedDateSlot as string].sessions[index]);
    }
  }



  return (
    <>
    {bookingPopup && (
      <ConfirmBooking setPopUp={setBookingPopup} 

                      start_date={selectedDateSlot as string} 
                      start_time={selectedTimeSlot.from} 
                      end_time={selectedTimeSlot.to} 
                      userDetails={userDetails}
                      mentor_id={mentor_id}/>
    )}
    <div className="available-sessions-com rounded-[16px]">
      <Toaster/>
      
      {
        deleteSessionPopup && (
          <div className="fixed inset-0 w-full h-full bg-black bg-opacity-80 flex items-center justify-center z-20" onClick={() => setDeleteSessionPopup(false)}>
            <div className="!bg-white rounded-xl p-[16px] text-black relative flex flex-col gap-[16px]" onClick={(e) => e.stopPropagation()}>
              <button className="absolute top-[16px] right-[16px]" onClick={() => setDeleteSessionPopup(false)}>
                <IoCloseCircleOutline className="text-[24px] hover:text-red-600" />
              </button>
              <div className="flex flex-col gap-[8px] mt-[16px]">
                <h1 className="text-xl font-medium">Confirm Session Deletion</h1>
                <p className="text-gray-600 text-lg font-normal">Are you sure you want to delete the session?</p>
              </div>
              {
                sessionCreationLoader === true ? (
                  <div>
                    <button className="w-full p-[10px] bg-red-500 text-white text-2xl font-semibold rounded-x flex items-center justify-center rounded-xl"> <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24">
                      <path fill="currentColor" d="M12,4a8,8,0,0,1,7.89,6.7A1.53,1.53,0,0,0,21.38,12h0a1.5,1.5,0,0,0,1.48-1.75,11,11,0,0,0-21.72,0A1.5,1.5,0,0,0,2.62,12h0a1.53,1.53,0,0,0,1.49-1.3A8,8,0,0,1,12,4Z">
                        <animateTransform attributeName="transform" dur="0.75s" repeatCount="indefinite" type="rotate" values="0 12 12;360 12 12" />
                      </path>
                    </svg></button>
                  </div>
                ) : (
                  <div>
                      <button className = "w-full p-[10px] bg-red-500 text-white text-2xl font-semibold rounded-xl" onClick={handleDeleteSession}>Confirm</button>
                  </div>
                )
              }
              
            </div>
          </div>
        )
      }
      <h2 className="available-sessions-heading">Available Sessions</h2>
      <h3 className="available-sessions-sub-heading">
        {"Book 1:1 sessions from the below options"}
      </h3>
      <div className="date-slots overflow-x-auto no-scrollbar">

        {mappedDateSlots && Object?.entries(mappedDateSlots)?.map(([date, { sessions, count }]) => (
          <div key={date} onClick={() => handleDateSlotClick(date)}
            className={`${selectedDateSlot === date ? '!border-txtPurple shadow-md' : ''} min-w-[100px] date-slot-item duration-200 ease-in`}>
            <span className="slot-day">{getDayFromDate(date)}</span>
            <span className="slot-date">{getMonthDateFromDate(date)}</span>
            <span className="available-slots">({count} sessions)</span>
          </div>
        ))}
      </div>


      <h3 className="available-sessions-sub-heading">
        {"Available time slots"}
      </h3>

      <h1 className="available-slots">{getMonthDateFromDate(selectedDateSlot as string)}</h1>
      <div className="date-slots overflow-x-auto no-scrollbar flex flex-row flex-wrap">
        {mappedDateSlots && selectedDateSlot && mappedDateSlots[selectedDateSlot]?.sessions?.map((session, index) => (
          <div key={index} onClick={() => handleTimeSlotClick(index)}
            className={` ${selectedTimeIndex === index ? '!border-txtPurple shadow-md' : ''}  flex flex-col p-[8px] rounded-[8px] gap-[8px] border-2 border-gray-200 duration-200 ease-in cursor-pointer`}>
            <div className="flex flex-row whitespace-nowrap gap-[4px]">
              <p className="slot-day">{convertToAMPM(session.from)}</p>
              <span>{"-"}</span>
              <p className="slot-day">{convertToAMPM(session.to)}</p>
            </div>
          </div>
        ))}
      </div>
      
      {
        view_state === "public" ? 
          <button className={`${selectedDateSlot !== null && selectedTimeIndex !== null ? 'opacity-100 cursor-pointer' : 'opacity-20 cursor-not-allowed shadow-md'} text-white  bg-black font-medium text-[1.5rem] p-[10px] rounded-[8px] duration-200 ease-in`}
            onClick={() => {
              if(selectedDateSlot !== null && selectedTimeIndex !== null){
                setBookingPopup(true)
              }
            }}>
            Book session {getMonthDateFromDate(selectedDateSlot as string)}
          </button>
        : 
        <button className={`${selectedDateSlot !== null && selectedTimeIndex !== null ? 'opacity-100 cursor-pointer' : 'opacity-20 cursor-not-allowed shadow-md'} text-white  bg-red-500 font-medium text-[1.5rem] p-[10px] rounded-[8px] duration-200 ease-in`}
            onClick={() =>{
              if (selectedDateSlot !== null && selectedTimeIndex !== null) {
                setDeleteSessionPopup(true)
              }
            }}>
            Delete session {getMonthDateFromDate(selectedDateSlot as string)}
          </button>
      }
     
    </div>
    </>
  );
}
