import { Link } from "react-router-dom";
import { RiTwitterXFill } from "react-icons/ri";
import hostedImageUrls from "../../utils/hosted_image";


interface SetStateProps {
  onHeroChange: React.Dispatch<React.SetStateAction<string>>;
  landingState: string;
}

export default function Footer({ onHeroChange, landingState }: SetStateProps) {
  return (
    <div>
      <div className="flex md:flex-row flex-col  justify-between   gap-4 p-[20px]">
        <div className="flex lg:w-[250px] lg:items-start justify-center items-center ">
          <img
            src={hostedImageUrls.growbinar_logo_short}
            alt="Grow_logo_full"
            className=" w-[192px] h-[192px] ml-3"
          />
        </div>
        <div className="grid sm:grid-cols-2 grid-cols-1">
          <div className="flex flex-col space-y-3 p-[16px]">
            <p className="footer-heading">Company </p>
            <p className="footer-data">About</p>
            <p className="footer-data">Become a mentor </p>
            <p className="footer-data">Careers</p>
            <p onClick={()=>onHeroChange('contactus')} className="footer-data inline cursor-pointer">
              ContactUs
            </p>
          </div>

          <div className="flex md:flex-col flex-row gap-[16px] justify-start md:items-start items-center p-[12px]">
            <Link
              to={"https://www.linkedin.com/company/growbinar/"}
              target="_blank"
              className="flex md:w-[256px] h-[56px] items-center gap-4"
            >
              <img src={hostedImageUrls.linkedin_logo} alt="linkedin" />
              <p className="footer-data md:block hidden">
                Follow us on LinkedIn
              </p>
            </Link>
            
            <Link
              to={"https://www.instagram.com/growbinar"}
              target="_blank"
              className="flex md:w-[256px] h-[56px] items-center gap-4"
            >
              <img src={hostedImageUrls.instagram_logo} alt="instagram" />
              <p className="footer-data md:block hidden">
                Follow us on Instagram
              </p>
            </Link>
            <Link
              to={"https://x.com/growbinar?t=wsw0krgEO0QIRfp0jsD4bw&s=08"}
              target="_blank"
              className="flex md:w-[256px] h-[56px] items-center gap-4"
            >
              <div className="rounded-full overflow-hidden w-[50px] h-[50px] bg-gbpurple items-center justify-center flex">
                <RiTwitterXFill className="text-[25px] text-white" />
              </div>  
              <p className="footer-data md:block hidden">
                Follow us on Twitter
              </p>
            </Link>
          </div>
        </div>
      </div>
      <div className="flex justify-end items-center mt-3 mr-3">
        <p className="text-[#646A69] text-[12px] font-thin font-CircularStdMedium leading-5 ">
          © 2024 Growbinar. All rights reserved.
        </p>
      </div>
    </div>
  );
}
