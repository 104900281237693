import  { useState } from "react";
import SignupLoginHeroContent from "../../pages/SignUpLoginHero";

import SignUpContent from "../../pages/SignUp";
import LoginContent from "../../pages/Login";

interface SetStateProps {
  url:string;
}

const SignUpLoginLayout = ({url}:SetStateProps) => {
  const [state, setState] = useState(url);
  return (
    <div>
      <div className="flex h-screen overflow-hidden">
        <div className="w-2/5 hidden lg:block bg-gbpurple">
          <SignupLoginHeroContent state={state} />
        </div>
        <div className="flex-grow justify-between bg-white">
          {url === "login" ? (
            <LoginContent setSignup={setState} />
          ) : (
            <SignUpContent setLogin={setState} cntEmail="EmailSignup" />
          )}
        </div>
      </div>
    </div>
  );
}


export default SignUpLoginLayout;