import { Link, useNavigate } from "react-router-dom";
import toast , {Toaster} from 'react-hot-toast'
import Cookies from "js-cookie";
import axios from "axios";
import Auth from "../../hooks/useAuth";
import hostedImageUrls from "../../utils/hosted_image";
import { useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";

export default function EmailLanding() {
  const navigate = useNavigate();
  const token = Cookies.get('GB_ACCESS_TOKEN');
  const {setAuthed} = useContext(AuthContext);
  
  const handleSubmit = async() => {
    
    const response = await Auth();
    if(response?.authed  && response?.verified){
        setAuthed(response)
        if(response?.role === "mentee"){
          navigate('/menteestepper')
        }else{
          navigate('/mentorstepper')
        }
    }
    else if(response?.authed && !response?.verified){
      toast.error('Verify your email to continue');
    }
  };

  const handleResendMail = async () =>{
    // resend email api
    try{
      const response = await axios.get(`${process.env.REACT_APP_GROWBINAR_BACKEND_URL}/resend_mail/`,{
        headers:{
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      })
      toast.success(response.data.message,{
        position:'top-right'
      })
    }catch(err:any){

      toast.error(err.response.data.message)
    }
  }
 
  return (
    // email verification landing
    <div className="flex items-center justify-center h-[calc(100vh-70px)] overflow-hidden">
      <Toaster/>
      <div className="flex flex-col items-center justify-start  w-[600px] h-[550px] rounded-[30px] bg-slate-50 flex-shrink-0 mt-[50px]">
        <img src={hostedImageUrls.email_bird} alt="verify_bird" />
        <p className="font-CircularStdBold text-2xl text-center">
          Verify your email
        </p>
        <div className=" w-[400px] h-[50px] p-4">
          <p className="font-CircularStdBlack text-base text-center whitespace-pre-line ">
            To keep a trusted and safe community, we’ve sent an email to{" "}
            <span className="font-CircularStdBold">
              {`"${localStorage.getItem("GB_USER_EMAIL")}"`}
            </span>{" "}
            for verification , and you’ll only have to do this once.
          </p>
        </div>
        {/* have to change the state to signup page email section  */}
        <p className="font-CircularStdBlack text-base mt-[70px]">
          Not the correct email?{" "}
          <Link to={"/signup"}>
            <span className="font-CircularStdBold"> change email address </span>
          </Link>
        </p>
        <button
          className="w-[479px] h-[54px] text-white bg-gbpurple rounded-lg gap-4 items-center justify-center mt-8"
          onClick={handleSubmit}
        >
          <p className="text-base font-CircularStdMedium font-normal ">
            Continue with Email
          </p>
        </button>
        <p className="font-CircularStdBlack text-base mt-[35px]">
          Did not recieve?{" "}
          <button onClick={handleResendMail}>
            <span className="font-CircularStdBold">Resend email </span>
          </button>
        </p>
      </div>
    </div>
  );
}
