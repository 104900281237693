import search from "../../assets/images/Search.svg"
import '../Header/index.css';
import notify from "../../assets/images/notify.svg";
import { Link } from 'react-router-dom';
import hostedImageUrls from "../../utils/hosted_image";

export default function Header() {
  return (
    <div className='flex 
        items-center justify-between'>

      <Link className='md:block hidden object-contain max-h-[80px] w-[180px]' to={'/'}>
        <img src={hostedImageUrls.growbinar_logo_full} alt='Growbinar logo' />
      </Link>

      <div className='mx-auto md:flex hidden items-center mb-[14px]'>
        <span className='relative'>
          <input type='search' className='bg-transparent w-64 h-[40px] rounded-lg border hover:border-gbpurple text-navtext p-3'
            placeholder='Search mentors'
          />
          <img className='absolute right-[10.5px] top-[8px] cursor-pointer' src={search} alt='search icon' />
        </span>
        <img className='hover:bg-hoverpurple hover-shadow-lg transition 
          duration-300 p-2 rounded-full cursor-pointer ml-2' src={notify} alt='notification icon ' />
        <div className='w-[250px]'></div>
      </div>
    </div>
  )
}
