import axios from "axios"
import Cookies from "js-cookie"


const Auth = async () => {

    try {
        const token = Cookies.get('GB_ACCESS_TOKEN')   // get the token from the cookies

        const response = await axios.get(`${process.env.REACT_APP_GROWBINAR_BACKEND_URL}/common_endpoint/`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        
        return {status : 200 , authed:true ,verified: true, role:response.data.role}   // return the status and role of the user

    } catch (err: any) {
     
        if (err.response.status === 403) {
            return { status: 403, authed: false ,verified:false, role:"" }   // return the status of the user if unauthorized
        }
        if (err.response.status === 400 && err.response.data.message === "User details not completely entered.") {
            return { status: 400, authed: true ,verified:true, role:err.response.data.role }  // return the status and role of the user if the stepper is not filled
        }
        if (err.response.status === 400 && err.response.data.message === "Verify your email") {
            return { status: 400, authed: true, verified: false, role: err.response.data.role }  // return the status and role of the user if the stepper is not filled
        }
    }
}

export default Auth;