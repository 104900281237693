import "./index.css";
import { useEffect, useState } from "react";
import PrivateMentorProfile from "./PrivateMentor";
import PublicMentorProfile from "./PublicMentor";
import { useParams } from "react-router-dom";



export default function MentorProfile() {
  const current_user = localStorage.getItem('GB_USER_ID')   // id of the current logged in user
  const [role, setRole] = useState<string>();   // role of the current logged in user
  const { userId } = useParams();  // id of the searched mentor

  useEffect(()=>{
    setRole(localStorage.getItem('userRole') as string);
  },[])

  return (
    <>
      <div>
        {current_user === userId && role === "mentor" ?   // if current id and searched id are same it means private profile
            <PrivateMentorProfile userId={current_user} />   // else public profile
              : <PublicMentorProfile current_user={current_user} view_user={userId} />}
      </div>
    </>
  );
}
