
import locationMarker from "../../assets/images/location-marker.svg";
import globe from "../../assets/images/globe.svg";
import briefcase from "../../assets/images/briefcase.svg";
import rightArrow from "../../assets/images/rightArrow.svg";
import "./index.css";
import MentorBackgroundDetailsCard from "../../components/MentorBackgroundDetailsCard/MentorBackgroundDetailsCard";
import MentorExperienceCard from "../../components/MentorExperienceCard/MentorExperienceCard";
import AvailableSession from "../../components/AvailableSession/AvailableSession";
import MentorCardsContainer from "../MentorCardsContainer/MentorCardsContainer";
import { RiVolumeUpFill } from "react-icons/ri";
import { FcLike } from "react-icons/fc";
import { FaRegMessage } from "react-icons/fa6";
import { TbCoinRupeeFilled } from "react-icons/tb";
import { useEffect, useState } from "react";
import axios from "axios";
import AddExperiencePopUp from "./AddExperiencePopUp";
import { useParams } from "react-router-dom";
import { convertToAMPM, getMonthDateFromDate } from "../../utils/HelperFunctions";
import ConfirmBooking from "../../components/ConfirmBooking";
import ThreedotLoader from "../../components/ThreedotLoader";
import { Link } from "react-router-dom";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';


interface background {
    fluency: string[];
    expertise: string[];
}

interface MentorProfileDetailResponse {
    name: string;
    organisation: string;
    overview: string;
    location: string;
    background: background;
    "Available-Sessions": avaliableDateTimeObj[],
    profile_image_url:string;
    years_of_experience:number;
}

interface avaliableDateTimeObj {
    to: string;
    from: string;
    date: string;
}

interface MentorExpericence {
    designation: string;
    organization: string;
    description: string;
    startDate: string;
    endDate: string;
}

interface PublicMentorProps{
    view_user: string | undefined;
    current_user:string | null;
}

// view user -> mentor profile which is being viewed

// current user -> id of the current user who is viewing the profile


//mui
const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

const PublicMentorProfile: React.FC<PublicMentorProps> = ({ view_user, current_user}) => {

    const [profileData, setProfileData] = useState<MentorProfileDetailResponse>({ // storing the profile details of mentor in this state
        name: "",
        organisation: "",
        overview: "",
        location: "",
        background: {
            fluency: [],
            expertise: [],
        },
        "Available-Sessions": [],
        profile_image_url:"",
        years_of_experience:0,
    });

    const [userProfilePath] = useState<boolean>(false);
    // if the user is visiting his own profile then userProfilePath is true
    const [MentorExpericence, setMentorExpericence] = useState<MentorExpericence[]>([]);
    const [experience, setExperience] = useState<MentorExpericence>({
        designation: "",
        organization: "",
        description: "",
        startDate: "",
        endDate: ""
    });

    const [isError, setIsError] = useState(false);

    const [popup, setPopup] = useState(false);

    const [sessionCreationLoader, setSessionCreationLoader] = useState<boolean>(false);
    const [bookingPopup, setBookingPopup] = useState<boolean>(false);
    const {userId} = useParams();  // getting the id of the user from the url


    useEffect(() => {
        const fetchData = async () => {    // fetcing data of the mentor profile
            try {
                const response = await axios.get(`${process.env.REACT_APP_GROWBINAR_BACKEND_URL}/profile/mentor_details/?id=${userId}`)
                setProfileData(response.data.data)
                setMentorExpericence(response.data.data.experience)
            }
            catch (err) {
                setIsError(true)
            }
        }
        fetchData()
    }, [userId])


    const handleAddExperience = async () => {
    
        if (experience) {
            setMentorExpericence([...MentorExpericence, experience]);
        }
        setPopup(false);
        setExperience({
            designation: "",
            organization: "",
            description: "",
            startDate: "",
            endDate: ""
        })
    }

    if (isError) {    // if error in fetching the user details which means id is invalid, so user not found
        return (
            <div className="h-full w-full flex justify-center items-center text-2xl font-CircularStdBold">
                <div>user not found</div>
            </div>
        )
    }


    if (profileData.name === "") {
        return <>
            <div className="flex justify-center items-center w-full h-full inset-0 fixed">
                <ThreedotLoader />
            </div>
        </>
    }

    return (
        <div className="flex flex-col gap-[20px] relative">
            {
                bookingPopup && (
                    <ConfirmBooking setPopUp={setBookingPopup} userDetails={profileData} start_date={profileData["Available-Sessions"][0]?.date} start_time={profileData["Available-Sessions"][0]?.from} end_time={profileData["Available-Sessions"][0]?.to} mentor_id={view_user}/>
                )
            }
            <div className="bg-cover-container">

            </div>
            {/* Profile Pic */}

            <div className="px-4 relative flex flex-col items-center gap-[10px] mt-[20px]">
                <div className="absolute -top-[150px]">
                    <span className="inline-block rounded-full object-contain overflow-hidden  border-white
                    md:h-[200px] md:w-[200px] md:border-[10px] 
                    sm:h-[180px] sm:w-[180px] sm:border-[9px]
                    h-[160px] w-[160px] border-[8px]">
                        <img src={profileData?.profile_image_url} alt="profilepic" />
                    </span>
                    <RiVolumeUpFill className="absolute flex items-center justify-center rounded-full md:text-[40px] text-[32px] p-1 text-txtPurple bg-[#f0effe] bottom-[10px] right-[35px]" />
                </div>
            </div>

            {/* Like, Message, Appreciate */}

            <div className="px-[16px] flex flex-col gap-[8px]">
                <div className="md:mt-[0px] mt-[16px] sm:pr-10 flex justify-center md:justify-end w-full gap-[12px]">
                    <BootstrapTooltip title="Coming Soon">
                        <div className="flex gap-2 items-center hover:opacity-50 interaction-container">
                            <div className=" p-[8px]">
                                <FcLike className="text-[1.5rem]" />
                            </div>
                            <div className=" p-[8px]">
                                <FaRegMessage className="text-[1.1rem]" />
                            </div>
                            <div className="flex items-center gap-[8px] bg-black text-white px-[8px] py-[16px] rounded-xl font-medium leading-[19px]">
                                <TbCoinRupeeFilled className="text-[1.1rem] text-yellow-300" />
                                <span className="font- ">Appreciate</span>
                            </div>
                        </div>
                    </BootstrapTooltip>
                </div>
                <div className="flex flex-col justify-center items-center gap-[8px]">
                    {/* Mentor Profile Details */}
                    <p className="text-[1.8rem] font-medium"> {profileData?.name}</p>
                    <p className="text-[1.2rem] font-normal text-gray-600"> {profileData?.organisation}</p>
                    {/* should add role here (ex: co-founder at devoice) */}
                    <div className="flex flex-col md:flex-row items-center justify-center mb-5 gap-[20px] text-[#636b78] overflow-hidden">
                        <div className="flex flex-row items-center w-auto gap-[8px]">
                            <span>
                                {" "}
                                <img src={locationMarker} alt="location-marker" />
                            </span>
                            <p>{profileData?.location}</p>
                        </div>

                        <div className=" flex flex-row items-center w-auto gap-[8px]">
                            <span>
                                {" "}
                                <img src={globe} alt="location-marker" />
                            </span>
                            <p>{
                                profileData?.background.fluency.map((item) => {
                                    return item + " "
                                })
                            }</p>
                        </div>

                        <div className=" flex flex-row items-center justify-center w-auto gap-[8px]">
                            <span>
                                {" "}
                                <img src={briefcase} alt="location-marker" />
                            </span>
                            <p>{
                                (profileData.years_of_experience === 0 || !profileData.years_of_experience) ? "Fresher" : ((profileData.years_of_experience === 1) ? `${profileData.years_of_experience} year of experience` : `${profileData.years_of_experience} years of Experience`)
                            }</p>
                        </div>
                    </div>
                </div>

                <div className="flex flex-row items-center justify-center rounded-[10px] p-[8px] gap-[16px] shadow-md hover:shadow-sm duration-200">
                    <div className="text-start sm:text-center flex gap-[8px] flex-col sm:flex-row">
                        {
                            profileData["Available-Sessions"][0]?.date === undefined ? <p className="text-[1rem] font-semibold">{"No sessions available"}</p> : 
                            <>
                                    <div className="flex flex-row gap-[4px] px-[16px] py-[8px] items-center">
                                        <p className="text-[1rem] font-semibold">{"Next Available"}</p>
                                        <span className="text-[#10de16]">{` ${getMonthDateFromDate(profileData["Available-Sessions"][0]?.date) } | ${convertToAMPM(profileData["Available-Sessions"][0]?.from)}`}</span>
                                    </div>
                                    <button className="text-[1.2rem] font-medium text-[#f9f9fa] bg-[#6c63ff] rounded-[8px] px-[16px] py-[8px]" onClick={() => setBookingPopup(true)} >Book</button>
                            </>
                        }
                        
                    </div>
                   
                </div>
                <div className="flex flex-col mt-5 lg:flex-row gap-[16px] justify-center items-center md:items-start overview-experience-and-availabilities">

                    {/* left side of profile */}

                    <div className="w-full flex flex-col gap-[16px] lg:w-[48%]  md:leading-[40px] sm:leading-[30px] leading-[20px]">

                        {/* overview about the user */}

                        <div className="flex flex-col min-h-[180px] sm:gap-[16px] gap-[8px] sm:p-[16px] p-[12px] rounded-[16px] border border-[#d1d4d6]">
                            <h3 className="text-2xl font-semibold">Overview</h3>
                            <div className="justify-bez`tween text-gray-600 font-medium">
                                {
                                    profileData?.overview
                                }
                            </div>
                        </div>

                        {/* Background of the user */}
                        <div className="flex flex-col min-h-[180px] sm:gap-[16px] gap-[8px] sm:p-[16px] p-[12px] rounded-[16px] border border-[#d1d4d6]">
                            <h3 className="text-2xl font-semibold">Background</h3>
                            <div className="flex flex-col">
                                <MentorBackgroundDetailsCard
                                    education="IDC, Mumbai"
                                    experience={profileData?.background?.expertise}
                                    fluency={profileData?.background?.fluency}
                                />
                            </div>
                        </div>

                        {/* Experience of the user */}

                        <div className="flex flex-col min-h-[180px] sm:gap-[16px] gap-[8px] sm:p-[16px] p-[12px] rounded-[16px] border border-[#d1d4d6]">
                            <h3 className="text-2xl font-semibold">Experience</h3>
                            <div className="content">
                                {
                                    MentorExpericence.map((item, index) => {
                                        return (
                                            <MentorExperienceCard
                                                designation={item.designation}
                                                organization={item.organization}
                                                description={item.description}
                                                startDate={item.startDate}
                                                endDate={item.endDate}
                                                key={index}
                                            />
                                        )
                                    })
                                }
                                
                            </div>
                            <button className={`${userProfilePath ? "block" : "hidden"} add-exp`} onClick={() => setPopup(true)}>
                                Add your Experience
                            </button>
                        </div>
                        {/* add a popup form that collects the data.. organization role and description */}
                        <div className={`${popup ? "block" : "hidden"} fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 transform duration-500 ease-in-out`}>
                            <AddExperiencePopUp setPopup={setPopup} experience={experience} setExperience={setExperience} handleAddExperience={handleAddExperience} loader = {false}/>
                        </div>
                    </div>

                    {/* Right side of the profile */}

                    <div className="w-full flex flex-col lg:w-[48%]  md:leading-[40px] sm:leading-[30px] leading-[20px]">
                        <div className="flex gap-[16px] flex-col">
                            {/* availblesession data */}
                            <AvailableSession data={profileData["Available-Sessions"]} mentor_id={view_user} view_state={"public"} userDetails={profileData}/>

                            {/* similar profile */}

                            <div className="overview-details-container">
                                <div className="flex flex-col items-center gap-4 heading-sect">
                                    <h3 className="text-2xl font-semibold">Similar Profiles</h3>
                                    <Link to={'/mentee/explore'} className="browse-similar-mentors-btn">
                                        <p>Browse Mentors</p>
                                        <span>
                                            <img src={rightArrow} alt="arrow-right" />
                                        </span>
                                    </Link>
                                </div>
                                <div className="flex flex-row overflow-x-auto gap-[12px]">
                                    {/* mentor card */}
                                    <MentorCardsContainer />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PublicMentorProfile;