import { useEffect, useState } from 'react'
import { Country, State } from 'country-state-city';
import { MeeteeFormState, MenteeFormError } from '../MenteeStepper';
import ValidationError from '../../components/ValidationError';
import hostedImageUrls from '../../utils/hosted_image';

import { AiOutlineClose } from "react-icons/ai";

interface MenteeProfileProps {
  formState: MeeteeFormState;
  updateFormState: Function;
  formError: MenteeFormError;
  handleSelectedImage :Function;
  selectedImage :any;
}

export const MenteeImage = [
  {
    path: hostedImageUrls.blankprofile,
    url: hostedImageUrls.Mentee_M_1,
    key: -1,
  },
  {
    path: hostedImageUrls.Mentee_M_1,
    url: hostedImageUrls.Mentee_M_1,
    key: 0,
  },
  {

    path: hostedImageUrls.Mentee_M_2,
    url: hostedImageUrls.Mentee_M_2,
    key: 1,
  },
  {
    path: hostedImageUrls.Mentee_M_3,
    url: hostedImageUrls.Mentee_M_2,
    key: 2,
  },
  {
    path: hostedImageUrls.Mentee_G_1,
    url: hostedImageUrls.Mentee_G_1,
    key: 3,
  },
  {

    path: hostedImageUrls.Mentee_G_2,
    url: hostedImageUrls.Mentee_G_2,
    key: 4,
  },
  {

    path: hostedImageUrls.Mentee_G_3,
    url: hostedImageUrls.Mentee_G_3,
    key: 5,
  },
  {
    path: hostedImageUrls.Mentee_M_4,
    url: hostedImageUrls.Mentee_M_4,
    key: 6,
  },
  {
    path: hostedImageUrls.Mentee_M_5,
    url: hostedImageUrls.Mentee_M_5,
    key: 7,
  },
  {
    path: hostedImageUrls.Mentee_M_6,
    url: hostedImageUrls.Mentee_M_6,
    key: 8,
  },

  {
    path: hostedImageUrls.Mentee_G_4,
    url: hostedImageUrls.Mentee_G_4,
    key: 9,
  },
  {

    path: hostedImageUrls.Mentee_G_5,
    url: hostedImageUrls.Mentee_G_5,
    key: 10,
  },
  {
    path: hostedImageUrls.Mentee_G_6,
    url: hostedImageUrls.Mentee_G_6,
    key: 11,
  },
  {
    path: hostedImageUrls.Mentee_M_7,
    url: hostedImageUrls.Mentee_M_7,
    key: 12 ,
  },
]



export default function MenteeStepperProfile({ formState, updateFormState, formError, handleSelectedImage, selectedImage  }: MenteeProfileProps) {

  const [states, setStates] = useState([]);
  const [countryCode, setCountryCode] = useState('' as any)

  const [profileFormError , setProfileFormError] = useState<MenteeFormError>(formError as MenteeFormError);
  const [profileFormState , setProfileFormState] = useState<MeeteeFormState>(formState as MeeteeFormState);
  const [profileImageCon, setProfileImageCon] = useState<boolean>(false);

  useEffect(()=>{
    setProfileFormError(formError)
  },[formError])

  useEffect(()=>{
    setProfileFormState(formState)
  },[formState])


  // function to handle the country name changes and code changes

  const handleCountryChange = async (event: any) => {
  
    const countryIsoCode = event.target.value;
    if (countryIsoCode === "") {
      updateFormState("country","")
      updateFormState("city","")
      setStates([]) 
      return;
    }

    setCountryCode(countryIsoCode)
    const country: any = Country.getCountryByCode(countryIsoCode);
    updateFormState("country", country["name"])
    setStates(State.getStatesOfCountry(countryIsoCode) as any);
  };

  // function handles the state changes and updates the city name

  const handleStateChange = (event: any) => {
    const stateIsoCode = event.target.value;
    if (stateIsoCode === "" || countryCode === "") {
      updateFormState('city', "")
      return
    }
    const state: any = State.getStateByCodeAndCountry(stateIsoCode, countryCode);
    updateFormState("city", state["name"])
  };

  const Gender = ["Male", "Female", "Other"]

  const handlelImagePopup = () => {
    setProfileImageCon(!profileImageCon)
  }


  return (
    <>

      {profileImageCon && (
        <div className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center' onClick={() => setProfileImageCon(false)}>

          <div className='bg-white p-5  rounded shadow-lg relative flex flex-col gap-[16px]' onClick={(e) => e.stopPropagation()}>
            <h1 className='block text-center text-2xl font-semibold'>Select your avatar</h1>
            {/* <button className='absolute top-[50%] right-[1%]'><FaChevronRight className='text-[16px]' /></button> */}
            <button onClick={() => setProfileImageCon(false)} className='absolute top-[3%] right-[2%]'>
              <AiOutlineClose className='text-[16px]' />
            </button>
            <div className="lg:w-[600px] sm:w-[500px] w-full  rounded-xl flex flex-col gap-[16px] relative">
              <div className='grid md:grid-cols-5 sm:grid-cols-4 grid-cols-3 gap-[16px] w-full h-[350px] p-[8px] overflow-y-auto no-scrollbar'>
                {
                  MenteeImage.map((image, index) => {
                    return (
                      <>
                        <div className={`mx-auto z-40 ${selectedImage?.key === image.key ? ' ' : ''}`} onClick={() => handleSelectedImage(image.key)} key={index}>
                          <img src={image?.path} alt="Mentor" className={`w-[80px] h-[80px] rounded-xl z-10 ${selectedImage.key === image.key ? 'opacity-70 border-2 border-txtPurple' : ''} duration-200 ease-in hover:scale-105`} />
                        </div>
                      </>
                    )
                  })
                }
              </div>
              <div className='flex flex-row-reverse ' onClick={() => setProfileImageCon(false)}>
                <button onClick={() => setProfileImageCon(false)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex flex-row-reverse">
                  Select
                </button>
              </div>
            </div>


            {/* Your popup content goes here */}
          </div>
        </div>
      )}
      {/* profile input field */}

      <div className='flex flex-row gap-[12px] items-center'>
        <div className='w-[6.6rem] h-[6.6rem] rounded-full bg-[#A1A6AD] hover:bg-black overflow-hidden' onClick={handlelImagePopup}>
          <img src={selectedImage?.path} alt='profile'></img>
        </div>
        <div className='flex flex-col gap-[8px]'>
          <p className="text-txtPurple font-semibold">Set a profile picture</p>
          <p className="text-txtGrey">select a file</p>
        </div>
      </div>
      <div></div>


      {/* first name input field */}
      
      <div className='flex flex-col gap-[8px]'>
        <label htmlFor="first_name" className='text-xl font-CircularStdMedium'>First Name</label>
        <input
          className={`bg-white !opacity-100  md:w-[90%] w-full rounded-[32px] 
                      shadow-md p-[12px] outline-2  outline-gray-100  focus:outline-txtPurple duration-200 ease-in
                      ${profileFormError.first_name.length > 0 ? '!border-2 border-red-500 shake' : ''}`}
          onChange={(e) => updateFormState('first_name', e.target.value)}
          placeholder="Enter First Name"
          id="first_name"
          required value={profileFormState.first_name}
        ></input>
        <div className='md:w-[90%]'>
          <ValidationError errors={formError["first_name"]} />
        </div>

      </div>

      {/* last name input field */}

      <div className='flex flex-col gap-[8px] '>
        <label htmlFor="last_name" className='text-xl font-CircularStdMedium'>Last Name</label>
        <input
          className={`bg-white !opacity-100 md:w-[90%] w-full  rounded-[32px] shadow-md p-[12px] 
              duration-200 ease-in outline-2 border-none outline-gray-100  focus:outline-txtPurple`}
          onChange={(e) => updateFormState('last_name', e.target.value)}
          placeholder="Enter Last Name"
          id="last_name"
          required value={profileFormState.last_name}
        ></input>
      </div>

      {/* gender and phone number input field */}

      <div className='flex md:justify-between gap-[8px]'>
        <div className='md:w-[28%] flex flex-col gap-[8px]'>
          <label htmlFor="gender" className='text-xl font-CircularStdMedium'>Gender</label>
          <select required id="gender" className='outline-2  outline-gray-100  focus:outline-txtPurple duration-200 ease-in bg-white rounded-[32px] shadow-md p-[12px] border-r-[12px] border-solid border-transparent'
            value={profileFormState.gender}
            onChange={(e) => {
              updateFormState('gender', e.target.value)
            }}>
            {
              Gender.map((item, index) => {
                return (
                  <option className='' value={item} key={index}>{item}</option>
                )
              })
            }
          </select>

        </div>
        <div className=' flex flex-col gap-[8px] md:flex-0 w-full'>
          <label htmlFor="phone_number" className='text-xl font-CircularStdMedium'>Phone Number</label>
          <input
            id="phone_number"
            className={`bg-white !opacity-100 md:w-[86%] w-full rounded-[32px] shadow-md p-[12px] outline-2  outline-gray-100  focus:outline-txtPurple duration-200 ease-in
                      ${profileFormError.phone_number.length > 0 ? '!border-2 border-red-500 shake' : ''}`}
            onChange={(e) => updateFormState('phone_number', e.target.value)} placeholder="+91 ***** *****" value={profileFormState.phone_number}
            required
          ></input>
          <div className='md:w-[86%]'>
            <ValidationError errors={profileFormError["phone_number"]} />
          </div>
        </div>
      </div>

      {/* date of birth input field */}


      <div className='flex flex-col gap-[8px]'>
        <label htmlFor="dob" className="text-xl font-CircularStdMedium">Date of Birth:</label>
        <input
          className={`bg-white !opacity-100 md:w-[90%] w-full rounded-[32px] shadow-md p-[12px] outline-2 border-none outline-gray-100  focus:outline-txtPurple duration-200 ease-in ${profileFormError.date_of_birth.length > 0 ? ' outline outline-red-500 shake' : 'outline-none'}`}
          type="date" id="dob" value={profileFormState.date_of_birth}
          onChange={(e) => updateFormState('date_of_birth', e.target.value)}
          placeholder="" required />
        <div className='w-[90%]'>
          <ValidationError errors={profileFormError["date_of_birth"]} />
        </div>
      </div>

      {/* country and city input field */}

      <div className='flex flex-col gap-[8px]'>
        <label htmlFor="country" className='text-xl font-CircularStdMedium'>Country</label>
        <select id="country" className={`bg-white md:w-[90%] rounded-[32px]   ${profileFormError.country.length > 0 ? ' outline outline-red-500 shake' : 'outline-none'} border-r-[12px] border-solid border-transparent shadow-md p-[12px] duration-200 ease-in` }
                            onChange={handleCountryChange} 
                            // value={formState.country}
                                required>
          <option value={""} >Select Your Country</option>
          {
            Country.getAllCountries().map((country, index) => {
              return (
                <option key={country.isoCode} className='' value={country.isoCode}>{country.name}</option>
              )
            })
          }
        </select>
        <div className='md:w-[86%]'>
          <ValidationError errors={profileFormError["country"]} />
        </div>
      </div>

      <div className='flex flex-col gap-[8px]'>
        <label htmlFor="state" className='text-xl font-CircularStdMedium'>State</label>
        <select id="state" className={`bg-white md:w-[90%] rounded-[32px]   ${profileFormError.country.length > 0 ? ' outline outline-red-500 shake' : 'outline-none'} border-r-[12px] border-solid border-transparent shadow-md p-[12px] duration-200 ease-in` } onChange={handleStateChange} required>
          <option value={""}>Select your State</option>
          {
            (states as any[]).map((state, index) => {
              return (
                <option key={state.isoCode} className='' value={state.isoCode}>{state.name}</option>
              )
            })
          }
        </select>
        <div className='md:w-[86%]'>
          <ValidationError errors={profileFormError["city"]} />
        </div>
      </div>

    </>
  );
}
