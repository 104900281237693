import React from "react";
import Card from "./Card";
// import "./index.css";
interface CardData {
  profileImage: string;
  name: string;
  role: string;
  content: string;
  socialMedia: string;
  linkedInLink: string | null;
}

interface CardContainerProps {
  cards: CardData[];
}

const CardContainer: React.FC<CardContainerProps> = ({ cards }) => {
  const rows = [];
  for (let i = 0; i < cards.length; i += 4) {
    rows.push(cards.slice(i, i + 4));
  }

  return (
    <div>
      {rows.map((row, rowIndex) => (
        <div
          className="flex flex-wrap gap-[32px] mt-6 justify-center sm:p-0 p-[16px]"
          key={rowIndex}
        >
          {row.map((card) => (
            <Card
              profileImage={card.profileImage}
              name={card.name}
              role={card.role}
              content={card.content}
              media={card.socialMedia}
              linkedInLink={card.linkedInLink}
            />
          ))}
        </div>
      ))}
    </div>
  );
};

export default CardContainer;
