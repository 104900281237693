import React from "react";
import hostedImageUrls from "../../utils/hosted_image";

import "./index.css";

interface CardProps {
  id: string;
  name: string;
  designation: string;
  organization: string;
  language: string;
  experience?: number;
  slotTime?: string;
  profilePic: string;
  sessionsConducted?: number;
  tag?: string;
}

const MentorCard: React.FC<CardProps> = ({
  id,
  name,
  language,
  designation,
  organization,
  experience,
  slotTime,
  profilePic,
  sessionsConducted,
  tag,
}) => {
  return (
    //dont use link tag.. it will not reload the page.. using an a tag will be better [if you are already view a mentors profile and if you need to view another mentor profile which is recommended below.. using link tag will not reload the page.. ]
    <a
      className="flex flex-col min-h-[423.18px]  rounded-[8px] p-[16px] md:max-w-[270px] mx-auto w-[90%] gap-[12px] hover:shadow-none
            text-ellipsis border-[1px] border-gray-200 bg-gray-50 shadow-sm hover:border-txtPurple duration-200 ease-in"
      href={`/mentor/${id}`}
    >
      <div className="mentor-image-container">
        <img
          className="h-[200px] w-[200px] mentor-image rounded-full"
          src={profilePic}
          alt="mentor-profile-pic"
        />
        <span className="top-rated-mentor">
          {tag === "topRated" ? (
            <img src={hostedImageUrls.top_rated} alt="topRatedMentor" />
          ) : (
            tag && <span className="tagline"> {tag}</span>
          )}
        </span>
        {sessionsConducted === undefined && (
          <span className="mentor-language-tag">{language}</span>
        )}
      </div>
      <div className="mentor-details">
        <div className="mentor-name">
          <h3>{name}</h3>
        </div>
        <div className="mentor-designation-org">
          <h3 className="designation-org-text">
            {designation} at{" "}
            <span className="organization-name"> {organization} </span>
          </h3>
        </div>
        {sessionsConducted === undefined && (
          <span className="years-of-experience">
            {
              (experience === 0 || !experience) ? "Fresher" : ((experience === 1) ? `${experience} year of Experience` : `${experience} years of Experience` )
              }
          </span>
        )}
      </div>
      {sessionsConducted === undefined ? (
        <div className="slot-details">
          <div className="availability-details">
            <h4 className="slot-time">{slotTime}</h4>
          </div>
          <button className="book-mentor-button hover:opacity-80 duration-100 ease-in">Book</button>
        </div>
      ) : (
        <p className="sessions-conducted">
          Conducted {sessionsConducted} sessions
        </p>
      )}
    </a>
  );
};

export default MentorCard;

