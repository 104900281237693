
import "reactjs-popup/dist/index.css";
import ProfileCard from "../ProfileCard";
import {  useLocation, Link } from "react-router-dom";
import { MdOutlineRocketLaunch } from "react-icons/md";
import { FaBook } from "react-icons/fa";
import { IoHome } from "react-icons/io5";


interface sideNavBarProps {
  role:string | null;
}

export default function SideNavBar({ role }: sideNavBarProps) {

  const location = useLocation();

  return (
    <div
      className="flex items-center  left-0 sticky
            md:flex-col md:gap-[32px] md:w-auto md:justify-start md:p-2  md:ml-3 md:my-8 md:shadow-none
            flex-row gap-[24px] w-full justify-around p-[12px] shadow-md
            "
    >
      <>
        {/* home navigation side nav bar */}
        {/* we will be getting role as props.. instead of hardcoding we can use role to navigate */}
        <Link
          className="flex flex-col items-center justify-center group"
          to={`/${role}/home`}
        >
          <div
            className={`${
              location.pathname === `/${role}/home`
                ? "bg-hoverpurple shadow-lg"
                : ""
            } group-hover:bg-hoverpurple hover-shadow-lg transition duration-300 p-2 rounded-md `}
          >
            <IoHome
              className={`${
                location.pathname === `/${role}/home`
                  ? "text-gbpurple"
                  : "text-gray-500"
              }  group-hover:text-gbpurple  text-[24px] cursor-pointer `}
            />{" "}
          </div>
          <p
            className={`text-shadow ${
              location.pathname === `/${role}/home`
                ? "text-gbpurple"
                : "text-black"
            }  group-hover:text-gbpurple text-[14px] font-bold  `}
          >
            Home
          </p>
        </Link>

        {/* conditional rendering for mentee.. this explore component should be visible only for mentee */}
        {role === "mentee" && (
          <Link
            className="flex flex-col items-center justify-center group"
            to={"/mentee/explore"}
          >
            <div
              className={`${
                location.pathname === "/mentee/explore"
                  ? "bg-hoverpurple shadow-lg"
                  : ""
              } group-hover:bg-hoverpurple hover-shadow-lg transition duration-300 p-2 rounded-md`}
            >
              <MdOutlineRocketLaunch
                className={`${
                  location.pathname === "/mentee/explore"
                    ? "text-gbpurple"
                    : "text-gray-500"
                }  group-hover:text-gbpurple  text-[24px] cursor-pointer `}
              />{" "}
            </div>
            <p
              className={`text-shadow ${
                location.pathname === "/mentee/explore"
                  ? "text-gbpurple"
                  : "text-black"
              }  group-hover:text-gbpurple text-[14px] font-bold `}
            >
              Explore
            </p>
          </Link>
        )}

        {/* booking side nav bar */}
        {/* will navigate according to the role */}

        <Link
          className="flex flex-col items-center justify-center group"
          to={`/${role}/booking`}
        >
          <div
            className={`${
              location.pathname === `/${role}/booking`
                ? "bg-hoverpurple shadow-lg"
                : ""
            } group-hover:bg-hoverpurple hover-shadow-lg transition duration-300 p-2 rounded-md `}
          >
            <FaBook
              className={`${
                location.pathname === `/${role}/booking`
                  ? "text-gbpurple"
                  : "text-gray-500"
              } group-hover:text-gbpurple  text-[24px] cursor-pointer `}
            />
          </div>
          <p
            className={`text-shadow ${
              location.pathname === `/${role}/booking`
                ? "text-gbpurple"
                : "text-black"
            }  group-hover:text-gbpurple text-[14px] font-bold `}
          >
            Booking
          </p>
        </Link>

        <div className="md:flex flex-col items-center justify-center hidden">
          <div className="w-[53px] hover:bg-hoverpurple hover-shadow-lg transition duration-300 p-2 rounded-md">
            <ProfileCard role = {role} />
          </div>
          <p className="text-shadow hover:text-gbpurple text-[14px] font-bold ">
            Me
          </p>
        </div>
      </>
    </div>
  );
}
