import axios from "axios"
import { useEffect, useState } from "react";

import ThreedotLoader from "../../components/ThreedotLoader";
import MentorExperienceCard from "../../components/MentorExperienceCard/MentorExperienceCard";
import AddExperiencePopUp from "./AddExperiencePopUp";
import Cookies from "js-cookie";
import toast, { Toaster } from "react-hot-toast";


interface PrivateMenteeProps {
    userId: string | undefined;
}

interface MenteeExperience {
    designation: string;
    organization: string;
    description: string | "";
    startDate: string;
    endDate: string;
  }

 // it will only take the userid as props

const PrivateMentee : React.FC<PrivateMenteeProps> = ({userId}) =>{
    
    const [menteeDetails, setMenteeDetails] = useState<any | null>(null)
    const [userProfilePath] = useState<boolean>(false);
  // if the user is visiting his own profile then userProfilePath is true
  const [MenteeExperience, setMenteeExperience] = useState<MenteeExperience[]>([]);
  const [popup, setPopup] = useState(false);
  const [experience, setExperience] = useState<MenteeExperience>({
    designation: "",
    organization: "",
    description: "",
    startDate: "",
    endDate: ""
  });
  const [loader, setLoader] = useState<boolean>(false);

    useEffect(() => {
        fetchMenteeProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleAddExperience = async() => {
      setLoader(true);
        try{
          const response = await axios.post(`${process.env.REACT_APP_GROWBINAR_BACKEND_URL}/profile/experience/`,
            {
              role: experience.designation,
              title: "",
              company: experience.organization,
              description: experience.description,
              from_duration: experience.startDate,
              to_duration: experience.endDate
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${Cookies.get("GB_ACCESS_TOKEN")}`,
              }
            }
          )
          if (response.status === 200) {
            setMenteeExperience([...MenteeExperience, experience]);
            setPopup(false);
            setExperience({
              designation: "",
              organization: "",
              description: "",
              startDate: "",
              endDate: ""
            });
          }
        }
        catch(err){
          toast.error("Couldn't add experience. Please try again later.")
          console.log(err)
        }
        setLoader(false);
      }

    const fetchMenteeProfile = async () => {    // fetch the mentee profile details
        try {
            const response = await axios.get(`${process.env.REACT_APP_GROWBINAR_BACKEND_URL}/profile/mentees/?id=${userId}/`)
            setMenteeDetails(response.data.data)
            setMenteeExperience(response.data.data.experience)
        } catch (err: any) {
            return (
                <div className="h-full w-full flex justify-center items-center text-2xl font-CircularStdBold">
                    <div>user not found</div>
                </div>
            )
        }
    }

    if(menteeDetails === null){
        return <>
            <div className="flex justify-center items-center fixed inset-0">
                <ThreedotLoader />
            </div>   
        </>
    }



    return (
        <div className="">
          <Toaster/>
            {/* profile icon with designation */}
            <div className="flex md:flex-row flex-col gap-2 items-center justify-start p-[24px]">
                <div className="bg-slate-400 w-[108px] h-[108px] rounded-full overflow-hidden">
                    <img src={menteeDetails?.profile_image_url} className="" alt="profile"/>
                </div>
                <div className="text-center">
                    <p className="text-[26px] font-CircularStdBlack font-semibold leading-9 text-black">
                        {menteeDetails?.name}{" "}
                        <span className="text-[#A1A6AD] text-[20px] font-CircularStdBlack">
                            {`(${menteeDetails?.location})`}
                        </span>
                    </p>
                    <p className="text-[#3F3D4D] text-[20px] font-normal">
                        {menteeDetails?.role}{" "}
                        <span className="text-[#A1A6AD] text-[20px] font-normal">at </span>
                        {menteeDetails?.organization}
                    </p>
                </div>
            </div>

            {/* area of intrest */}
            <div className="flex gap-3 items-center justify-start p-[24px]">
                <div className=" text-xl font-CircularStdBlack font-semibold whitespace-nowrap">
                    Area of Interest :
                </div>
                {/* <div className=""> */}
                <div className="flex flex-1 gap-[8px] overflow-y-auto no-scrollbar">
                    {
                        menteeDetails?.areas_of_interest.map((interest:string,index:number)=>{
                                return(
                                    <>
                                        <div className="flex items-center justify-center text-center p-3 text-sm md:text-md whitespace-nowrap bg-[#B5F9CC] text-[#43AD54] rounded-lg">
                                            {interest}
                                        </div>
                                    </>
                                )
                        })
                    }
            
                </div>
            </div>

            {/* bio section  */}
            <div className="p-[25px] space-y-4">
                <p className="text-xl font-CircularStdBlack font-semibold text-gbpurple underline">
                    About Myself
                </p>
                <div className="border border-[#D1D4D6] rounded-lg w-full md:w-[80%] lg:w-[55%] h-fit p-4 whitespace-pre-wrap text-[#6A6977] font-CircularStdMedium ">
                    {menteeDetails?.overview}
                </div>
            </div>

            {/* experience */}
            <div className="w-full flex justify-center md:justify-start items-center p-[25px]">
              <div className="flex flex-col min-h-[180px] lg:w-[55%] sm:w-[90%] w-full sm:gap-[16px] gap-[8px] sm:p-[16px] p-[12px] rounded-[16px] border border-[#d1d4d6]">
                <h3 className="text-2xl font-semibold">Experience</h3>
                <div className="flex flex-col gap-8 content">
                  {
                    MenteeExperience.map((item, index)=>{
                      return(
                        <MentorExperienceCard
                          designation={item.designation}
                          organization={item.organization}
                          description={item.description}
                          startDate={item.startDate}
                          endDate={item.endDate}
                          key={index}
                        />
                      )
                    })
                  }
                </div>
                <button className={`${userProfilePath ?  "block" : ""} add-exp`} onClick={()=>setPopup(true)}>
                  Add your Experience
                </button>
              </div>
            </div>
            <div className={`${popup ? "block" : "hidden"} fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 transform duration-500 ease-in-out`} onClick={()=>{
            setExperience({
              designation: "",
              organization: "",
              description: "",
              startDate: "",
              endDate: ""
            })
            
            setPopup(false)
            }}>
              <AddExperiencePopUp setPopup={setPopup} experience={experience} setExperience={setExperience} handleAddExperience = {handleAddExperience} loader = {loader}/>
            </div>
        </div>
    );
}

export default PrivateMentee;