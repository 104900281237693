import React from "react";

interface ZeroDataInterface{
    one:string;
    two:string;
}

const ZeroData : React.FC<ZeroDataInterface> = ({one,two}) =>{
    return(
        <>
            <div className="min-h-[250px] flex flex-col items-center justify-center gap-[16px]">
                <h1 className="text-3xl font-bold">{one}</h1>
                <h3 className="text-xl font-medium text-gray-500">{two}</h3>
            </div>
        </>
    )
}

export default ZeroData;