import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import './index.css'
import { setCookie } from "../../utils/cookies/cookieCurd";
import toast , {Toaster} from 'react-hot-toast'
import hostedImageUrls from "../../utils/hosted_image";
import { AuthContext } from "../../contexts/AuthContext";

interface SetStateProps {
  setLogin: React.Dispatch<React.SetStateAction<string>>;
  cntEmail: string;
}

interface errors{
  emailError: string;
  passwordError: string;
}

export default function SignUpContent({ setLogin, cntEmail }: SetStateProps) {
  const [showEmailSignUp, setShowEmailSignUp] = useState(false);
  const [showSocialSignUp, setShowSocialSignUp] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role,setRole] = useState<string | null>(null);
  const navigate = useNavigate();
  const [error, setError] = useState<errors>({ emailError: "", passwordError: "" });
  const [loading, setLoading] = useState(1);
  const [showPassword, setShowPassword] = useState(false);

  const {setAuthed} = useContext(AuthContext);

  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  const handleEmailSignup = () => {
    setShowEmailSignUp(true);
    setShowSocialSignUp(false);
    
  };

  const handlePasswordRegex = (password : string) =>{    // function to handle password regex
    let passwordError = error.passwordError;
    if (!passwordRegex.test(password)) {
      passwordError = "Password must contain at least 8 characters, including uppercase, lowercase, numbers and special characters.";
      setError((prev) => { return { ...prev, passwordError } });
    }
    else {
      setError((prev) => ({ ...prev, passwordError: "" }));
    }
  }
  const handleEmailRegex = (email: string) => {      // function to handle regex for email
    let emailError = error.emailError;
      if (!emailRegex.test(email)) {
        emailError = "Invalid email address";
        setError((prev) => ({ ...prev, emailError }));
      } else {
        setError((prev) => ({ ...prev, emailError: "" }));
      }
   
    };
   
    const handleSocialSignUp = () => {
      setEmail("");
      setPassword("");
      setShowSocialSignUp(true);
      setShowEmailSignUp(false);
    };
  const handleSubmit = async  () => {
    setLoading(0);
    if(role !== "mentor" && role !== "mentee"){
      toast.error('Please select a role')
      setLoading(1)
      return;
    }

    handleEmailRegex(email)  // handle email regex pattern

    handlePasswordRegex(password)  // handle password regex pattern

  
    if(error.emailError === "" && error.passwordError === ""){
      try {
  
        const response = await axios.post(`${process.env.REACT_APP_GROWBINAR_BACKEND_URL}/${role}_signup/`, {
          email_id: email,
          password: password,
          user_role: role
        });
  
        setLoading(1)
        setCookie('GB_ACCESS_TOKEN', response.data.jwt_token, {
          expires: 7,
          path: '/',
          sameSite: 'None',
          secure: true,
        })
        localStorage.setItem('GB_USER_ID', response.data.id)
        localStorage.setItem('userRole', role as string);
        localStorage.setItem('GB_USER_EMAIL', email)
        setAuthed({status:400, authed:true, verified:false, role:role as string})
        navigate("/signup/emailVerification");

      } catch (err: any) {

        toast.error(err.response.data.message, {
          position: 'top-right',
        })
        setLoading(1);
      }
      setLoading(1);
    }
    setLoading(1);
  };
  
  const handleLogin = () => {
    setLogin("login");
    navigate("/login");
  };



  return (
    <div>
      {/* already have an account login section  */}
      <Toaster/>
      <div>
        <div className="flex flex-row gap-2 items-center justify-end p-4">
          <p className="text-base -tracking-[0.064px] font-normal font-CircularStdMedium ">
            Already have an account?
          </p>
          <button
            className="text-base rounded border-[2px] border-black font-normal font-CircularStdMedium p-1"
            onClick={handleLogin}
          >
            Log in
          </button>
        </div>

        {/* Social signup content */}
        <div className="flex flex-col items-center justify-center mt-[25px]">
          <div>
            <img
              src={hostedImageUrls.growbinar_logo_short}
              alt="growbinar_logo"
              className="w-[150px] h-[150px] flex-shrink-0"
            />
          </div>
          <div className="">
            <p className="text-center sm:text-[36px] text-[20px] font-light font-CircularStdBlack text-[#9F9F9F] whitespace-nowrap">
              Empower your career advancement.
            </p>
          </div>

          {showSocialSignUp && (
            <div className="flex flex-col gap-4 p-4 md:p-8 items-center justify-center w-full">
              <button className="flex w-full sm:w-[479px] h-[54px] border-[2px] border-black rounded-lg gap-4 items-center justify-center opacity-50 cursor-not-allowed">
                <img
                  src={hostedImageUrls.google_logo}
                  alt="google_logo"
                  className="w-[24px] h-[24px]"
                />
                <p className="text-base font-CircularStdMedium font-normal ">
                  Sign up with Google
                </p>
              </button>
              <button className="flex w-full sm:w-[479px] h-[54px] border-[2px] border-black rounded-lg gap-4  items-center justify-center opacity-50 cursor-not-allowed">
                <img
                  src={hostedImageUrls.linkedin_logo}
                  alt="google_logo"
                  className="w-[24px] h-[24px]"
                />
                <p className="text-base font-CircularStdMedium font-normal ">
                  Sign up with LinkedIn
                </p>
              </button>
              <p className="text-center font-CircularStdBlack text-[#9F9F9F] p-2">
                OR
              </p>
              <button
                className="h-[54px] w-full sm:w-[479px] border-[2px] text-white bg-gbpurple rounded-lg gap-4 items-center justify-center"
                onClick={handleEmailSignup}
              >
                <p className="text-base font-CircularStdMedium font-normal ">
                  SignUp with Email
                </p>
              </button>
            </div>
          )}

          {showEmailSignUp && (
            <div className="flex flex-col gap-4 p-4 md:p-8 items-center justify-center w-full">
              {/* Signup Form Content Here */}
              <div className=" w-full sm:w-[479px] text-left">
        
                <input
                  type="email"
                  value={email}
                  placeholder="Enter your email address"
                  onChange={(e) => {
                      setEmail(e.target.value.trim())
                      handleEmailRegex(e.target.value)
                  }}
                  className={`border-[2px] border-black rounded-lg w-full h-[56px] p-2 ${error.emailError ? 'border-red-500 shake' : ''}`}
                />
                {
                  error.emailError && <div className='w-full sm:w-[479px] rounded-[4px] text-red-500 font-medium'>
                      <p className='text-txtRed text-sm'>{error.emailError}</p>
                  </div>
                }
              </div>


              <div className=" w-full sm:w-[479px] text-left">
                {
                  showPassword ? <input
                    type="text"
                    placeholder="Enter your password"
                    value={password}
                    
                    onChange={(e) => {
                          setPassword(e.target.value)
                          handlePasswordRegex(e.target.value)
                    }}
                    className={`border-[2px] h-[54px] border-black rounded-lg w-full p-2 ${error.passwordError ? 'border-red-500 shake' : ''}`}
                  /> : <input
                    type="password"
                    placeholder="Enter your password"
                    value={password}
                    onChange={(e) => {
                        setPassword(e.target.value)
                        handlePasswordRegex(e.target.value)
                      }}
                    className={`border-[2px] border-black rounded-lg w-full h-[54px] p-2 ${error.passwordError ? 'border-red-500 shake' : ''}`}
                  />
                }
                {
                  error.passwordError && <div className='rounded-[4px] text-red-500 font-medium'>
                    <p className='text-txtRed text-sm'>{error.passwordError}</p>
                  </div>
                }
              </div >

              <div className="w-full sm:w-[479px] flex gap-4 items-center justify-end">
                <input type="checkbox" className="h-5 w-5" onChange={()=>{setShowPassword(!showPassword)}}/>
                <label className="text-base font-CircularStdMedium font-normal">Show Password</label>
              </div>

              <select className="border-[2px] border-black rounded-lg  w-full sm:w-[479px] h-[54px] text-left p-2" onChange={(e: any) => setRole(e.target.value)} disabled = {loading === 0 ? true:false}>
                <option value={'role'} disabled selected hidden>Role</option>
                <option className="" value={'mentee'}>Mentee</option>
                <option value={'mentor'}>Mentor</option>
              </select>
              
              <div className=" w-full sm:w-[479px] h-[54px] text-left ">
                  {
                    loading === 1 ? <button className="w-full sm:w-[479px] h-[54px] text-white bg-gbpurple rounded-lg gap-4 items-center justify-center"
                      onClick={handleSubmit}>
                      <p className="text-base font-CircularStdMedium font-normal">
                        Continue
                      </p>
                    </button>
                    :
                      <button className="w-full sm:w-[479px] h-[54px] text-white bg-gbpurple rounded-lg gap-4 flex items-center justify-center">

                        <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24">
                          <path fill="currentColor" d="M12,4a8,8,0,0,1,7.89,6.7A1.53,1.53,0,0,0,21.38,12h0a1.5,1.5,0,0,0,1.48-1.75,11,11,0,0,0-21.72,0A1.5,1.5,0,0,0,2.62,12h0a1.53,1.53,0,0,0,1.49-1.3A8,8,0,0,1,12,4Z">
                            <animateTransform attributeName="transform" dur="0.75s" repeatCount="indefinite" type="rotate" values="0 12 12;360 12 12" />
                          </path>
                        </svg>

                      </button>
                  }
              </div>
              <div className=" w-full sm:w-[479px] h-[54px] text-left ">
                <button
                  className="w-full h-full text-black bg-white rounded-lg gap-4 items-center justify-center border-[2px] border-black"
                  onClick={handleSocialSignUp}
                >
                  <p className="text-base font-CircularStdMedium font-normal ">
                    Go Back
                  </p>
                </button>
              </div>
              
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
