import MentorUpcomingContainer from "../../containers/MentorUpcomingContainer";
import { useEffect, useState} from "react";
import "./index.css";
import "react-calendar/dist/Calendar.css";
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import {Toaster} from "react-hot-toast";
import DateSessionPicker from "../../components/DatePicker";
import { useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import hostedImageUrls from "../../utils/hosted_image";



export default function MentorDashboard() {
  const today = new Date();
  const {userProfile, sessionCount} = useContext(AuthContext);
  const [value, setValue] = useState<Dayjs | null>(dayjs(today));

  return (
    <div className="md:bg-white bg-lightPurple">
      <Toaster/>
      <div className="p-[16px] items-center justify-center gap-[10px] bg-[#DDDAFF] md:bg-transparent">
      <p className="text-3xl font-semibold text-black ">Welcome Back <span className="grad-text capitalize">{userProfile?.name} 🚀</span></p>
        <p>
          You have
          <span className="text-gbpurple">
            {" "}
            {sessionCount.upcommingCount} upcoming sessions and {sessionCount.pendingCount} requests
          </span>
        </p>
      </div>
      <div className="p-[10px] flex flex-col gap-[16px]">
        <div className="">
          <p className="text-[#000] text-left text-[1.8rem] font-medium leading-[36px]">Upcoming sessions</p>
        </div>
        {/* //upcoming sessions cards -- make it responsive */}
        <div className="items-center justify-center gap-[10px]">
          <MentorUpcomingContainer />
        </div>
        {/* horizontal divider */}
        <div className=" py-[20px]">
          <div className="border-2 border-slate-200 divide-y-2 w-full"></div>
        </div>

        {/*graph and container*/}
        <div className="flex md:gap-10 flex-col md:flex-row gap-[16px] flex-wrap lg:items-start sm:items-center relative sm:p-4">

          {/* mentor calendar section */}
          <div className="flex-shrink-0 ">
            <div className="h-auto flex flex-col gap-[20px] ">
              <p className=" text-[26px] font-CircularStdMedium">
                Add your available session
              </p>
              <div className="w-full flex flex-col gap-[16px] ">
                {/* <Calender className="react-calendar" /> */}
                <DateSessionPicker/>
              </div>

            </div>
          </div>
          <div className="border border-[#c0c0c0] overflow-hidden rounded-md">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateCalendar value={value} onChange={(newValue) => setValue(newValue)} />
            </LocalizationProvider>
          </div>
        </div>

        {/*Made with love from india text */}
        <div className="mt-20 flex items-center mb-[100px] md:mb-0 lg:w-[60%]">
          <p className="text-[#b9b5f9] text-4xl md:text-7xl font-CircularStdBold">Made with love for india.</p>
          <img className="w-[100px] h-[100px] sm:w-[200px] sm:h-[200px]" src={hostedImageUrls.love_emoji} alt={"emoji"} />
        </div>  
      </div>
      
    </div>
  );
}
