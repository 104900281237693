import { Navigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Loader from "../../components/Loader";
import { useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";

interface RequireAuthProps {
    children: React.ReactNode;
}

const RequireAuth : React.FC<RequireAuthProps> = ({ children } : {children : any}) => {

    const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
    // The isAuthenticated state variable represents whether the user is authenticated or not.
    const [isStepper,setIsStepper] = useState<boolean | null>(null)
    // The isStepper state variable represents whether the user filled the stepper or not.
    const [role,setRole] = useState<string | null>(null)
    // The role state variable represents the role of the authenticated user.

    const {authed} = useContext(AuthContext);


    useEffect(()=>{
        if (authed?.status === 200) {   // check whether the user is authorized
            setIsAuthenticated(true)
            setIsStepper(true)
            setRole(authed?.role)
        }
        if (authed?.status === 400){   // check whether the user filled the stepper
            setIsAuthenticated(true)
            setIsStepper(false)
            setRole(authed?.role)
        }
        if (authed?.status === 403) {   // unauthorized user
            setIsAuthenticated(false)
            setIsStepper(false)
        }
   },[authed?.role, authed?.status])
   
   

    return isAuthenticated && isStepper ? <> {children} </> :   // if the user is authenticated and filled the stepper, render the children
        (isAuthenticated  === true && isStepper === false && role === "mentee") ? <Navigate to="/menteestepper" /> :  // if the user is authenticated but didn't fill the stepper, redirect to menteeStepper 
            (isAuthenticated === true && isStepper === false && role === "mentor") ? <Navigate to="/mentorstepper" /> :   // if the user is authenticated but didn't fill the stepper, redirect to mentorStepper
                    (isAuthenticated === false) ? <Navigate to="/login" /> :  // if the user is not authenticated, redirect to login page
                    <> 
                         <Loader />
        </>
}

export default RequireAuth;