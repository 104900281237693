function validateUsername(first_name : string) {
    const minLength = 3;  //Minimum length: 3 characters
    const maxLength = 20; // Maximum length: 20 characters
    const regex = /^[a-zA-Z_][a-zA-Z0-9_]*$/;  // Allowed characters: Letters (a-z, A-Z), numbers (0-9), and underscores (_)
    //Must not start with a number
    let bucket =  []
    if (first_name.length < minLength) {
        bucket.push("Username must be at least " + minLength + " characters long.");
    }
    if (first_name.length > maxLength) {
        bucket.push("Username must be no more than " + maxLength + " characters long.");
    }
    if (!regex.test(first_name)) {
        bucket.push("Username can only contain letters, numbers, and underscores, and must start with a letter or an underscore.");
    }
    if (bucket.length > 0) {
        return { error: true, bucket , key : "first_name" }
    }
    return  {error: false , key: "first_name"}
}

function validateFirstName(first_name : string) {
    const regex = /^[a-zA-Z ]+$/;
    let bucket =  []
    if(first_name.length === 0){
        bucket.push("First name is required");
        return { error: true, bucket , key : "first_name" }
    }
    if(!regex.test(first_name)){
        bucket.push("Numbers and special characters are not allowed");
    }
    if (bucket.length > 0) {
        return { error: true, bucket , key : "first_name" }
    }
    return {error: false , key: "first_name"}
}

function ValidatePhonenumber(phone_number : string) {
    const regex = /^(\+?91)?[\s-]?[0-9]{5}[\s-]?[0-9]{5}$/;
    let bucket =  [];
    if(!regex.test(phone_number)){
        bucket.push("invalid format of phone number");
    }
    if (bucket.length > 0) {
        return { error: true, bucket , key : "phone_number" }
    }
    return { error: false, key: "phone_number" }
}

function validateCountryState(value : string , key : string) {
    let bucket = [];
    if(value.length === 0){
        bucket.push("Please select a field");
        return {error: true, bucket , key}
    }
    return {error: false , key}
}

function validatePhoneNumber(phoneNumber : string) {
    // Remove all non-digit characters
    const digits = phoneNumber.replace(/\D/g, '');
    let bucket = []
    if (digits.length !== 10) {
         bucket.push("Phone number must contain exactly 10 digits.");
    }
    if(bucket.length > 0){
        return {error: true,  bucket , key : "phone_number"}
    }
    return {error:false , key: "phone_number"}
}

function validateAdditionalDatas(value : string , key : string) {
    let bucket = [];
    if(value.length === 0){
        bucket.push("Field cannot be empty");
        return {error: true, bucket , key}
    }
    return {error: false , key}
}

function validateAdditionDatasArray(value : string[] , key : string) {
    let bucket = [];
    if(value.length === 0){
        bucket.push("Field cannot be empty");
        return {error: true, bucket , key}
    }
    return {error: false , key}
}

function calculateAge(birthDate: string): number {
    // Parse the birth date string to a Date object
    const birth = new Date(birthDate);
    const current = new Date(); // Current date

    // Calculate age in years
    let age = current.getFullYear() - birth.getFullYear();
    const monthDifference = current.getMonth() - birth.getMonth();

    // Adjust age if the current date is before the birth date in the current year
    if (monthDifference < 0 || (monthDifference === 0 && current.getDate() < birth.getDate())) {
        age--;
    }

    return age;
}

function validateDOBFormat(dob : string) {
    // Regular expression to match the mm-dd-yy date format
    const regex = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/;
    const bucket = []
    if(regex.test(dob)){
        if(calculateAge(dob)<15){
            bucket.push("You must be at least 15 years old to register.");
            return {error: true, bucket , key: "date_of_birth"}
        }
        return {error: false , key: "date_of_birth"}
    }else{
        bucket.push("Date of birth must be in the format DD-MM-YY.");
        return {error:true, bucket , key: "date_of_birth"}
    }
}

export { validateUsername, validatePhoneNumber, validateDOBFormat, validateFirstName, ValidatePhonenumber, validateCountryState, validateAdditionalDatas, validateAdditionDatasArray };