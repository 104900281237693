import { Link } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import hostedImageUrls from "../../utils/hosted_image";

interface Props {
  push_front_to: string;
}

const ToggleHeader: React.FC<Props> = ({ push_front_to }) => {
  const { userProfile } = useContext(AuthContext);
  return (
    <>
      <div className="">
        <div
          className="flex flex-row py-[8px] pr-[8px]
                                items-center justify-between h-[80px]"
        >
            <Link to={'/mentee'} className="object-contain max-h-[80px] w-[180px]">
              <img src={hostedImageUrls.growbinar_logo_full} alt="Growbinar logo" />
            </Link>

          <Link
            to={`${process.env.REACT_APP_GROWBINAR_FRONTEND_URL}/${push_front_to}`}
            className="flex flex-col items-center justify-center"
          >
            <div className="avatar w-[53px] hover:bg-hoverpurple hover-shadow-lg transition duration-300 p-1 rounded-md">
              <img
                className="cursor-pointer rounded-full overflow-hidden"
                src={(userProfile?.profile_image_url) ? userProfile?.profile_image_url : hostedImageUrls.blankprofile}
                alt="profile image"
              />
            </div>
          </Link>
        </div>
      </div>
    </>
  );
};

export default ToggleHeader;
