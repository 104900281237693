import hostedImageUrls from "../../utils/hosted_image";

const Data = [
  {
    mentor_image: hostedImageUrls.landing_mentor1,
    name: "Dipanjan De",
    role: "Full Stack Data Scientist",
    company: "Vince AS",
    experience: "8 years",
    Mentees: "200+",
    Rating: "9.5/10",
  },
  {
    mentor_image: hostedImageUrls.landing_mentor2,
    name: "Anupama Mishra",
    role: "Product Design Lead",
    company: "PayPal",
    experience: "8 years",
    Mentees: "200+",
    Rating: "9/10",
  },
  {
    mentor_image: hostedImageUrls.landing_mentor3,
    name: "Shivam Trivedi",
    role: "Assoc. Director of Product Management",
    company: "Lal10",
    experience: "8 years",
    Mentees: "200+",
    Rating: "9/10",
  },
  {
    mentor_image: hostedImageUrls.landing_mentor4,
    name: "Karanraj Mohanasundaram",
    role: "Application Developer",
    company: "Thoughtworks",
    experience: "2 years",
    Mentees: "200+",
    Rating: "8.5/10",
  },
  {
    mentor_image: hostedImageUrls.landing_mentor6,
    name: "Aashish Gupta",
    role: "SDE II",
    company: "Uber",
    experience: "7+ year",
    Mentees: "200+",
    Rating: "8/10",
  },
];

export default Data;
