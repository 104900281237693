import  { useEffect, useState } from "react";
import LandingPageHeader from "../../components/LandingPageHeader";
import MenteeLandingPage from "../../pages/MenteeLandingPage";
import "./index.css";
import OurTeam from "../../pages/OurTeam";
import MentorLandingPage from "../../pages/MentorLandingPage";
import Contactus from "../../pages/Contactus";
import Footer from "../../components/Footer";

function LandingPageLayout(props: any) {
  const [landingState, landingSetState] = useState(props.landingState);


  return (
    <div className="main-wrapper">
      <div>
        <LandingPageHeader onHeroChange={landingSetState} landingState={landingState} />
      </div>
      <div>
        {landingState === "mentorLanding" && <MentorLandingPage key={"mentor"} />}
        {landingState === "menteeLanding" && <MenteeLandingPage key={"mentee"} />}
        {landingState === "ourTeam" && <OurTeam />}
        {landingState === "contactus" && <Contactus />}
      </div>
      <div>
        <Footer onHeroChange={landingSetState} landingState={landingState} />
      </div>
    </div>
  );
}

export default LandingPageLayout;
