import { useEffect, useState } from 'react'
import { getCookie } from '../../utils/cookies/cookieCurd'
import Cards from './Cards'
import axios from "axios"
import ZeroMessages from "../../utils/ZeroMessage/zeroMessage.json"
import ZeroData from '../../components/ZeroData'
import SingleCardLoader from '../../components/SingleCardLoader'



export default function MenteeUpcomingContainer() {
  const [menteeSessions, setMenteeSessions] = useState<any | null>(null);

  

  useEffect(()=>{
    fetchSessionsMentee()
  },[])

  const fetchSessionsMentee = async () => {

    // const 
    const token = getCookie('GB_ACCESS_TOKEN')
  
    try {

      const response = await axios.get(`${process.env.REACT_APP_GROWBINAR_BACKEND_URL}/sessions/upcoming_sessions_mentee/`, {

        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      })

      const data = response.data.data

      setMenteeSessions(() => 
        {
          return data.filter((session: any) => session.status === "Upcoming")
        }
      )

    } catch (err: any) {
      console.log(err)
    }
  }

  if (menteeSessions === null) {
    return (
      <>
        <div className='flex'>
          <SingleCardLoader />
        </div>
      </>
    )
  }

  return (
    <div>
      <div className='flex flex-row gap-2 overflow-x-auto no-scrollbar'>
        {
          menteeSessions && menteeSessions.length !== 0 ?
              menteeSessions.map((session : any, index : number)=>(
                <div className='w-[347px]   flex-shrink-0 border-[1px] bg-gray-100 shadow-sm rounded-[16px] hover:border-gbpurple p-[16px]' key={index}>
                  <Cards sessionDetails={session} />
                </div>
              ))
          :
          <>
            <ZeroData one={ZeroMessages["Upcoming"].one} two={ZeroMessages["Upcoming"].two}/>
          </>  
        }
        </div>
    </div>
  )
}
