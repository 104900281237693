import './index.css';
import allSvg from '../../assets/images/domain-filter-all.svg';
import uiUx from '../../assets/images/domain-filter-ui-ux.svg';
import marketing from '../../assets/images/domain-filter-marketing.svg';
import webDev from '../../assets/images/domain-filter-web-dev.svg';
import aiML from '../../assets/images/domain-filter-ai-ml.svg';
import seo from '../../assets/images/domain-filter-seo.svg';
import graphicDesign from '../../assets/images/domain-filter-graphic-design.svg';
import MentorCardsContainer from '../MentorCardsContainer/MentorCardsContainer';
import SearchBar from '../../components/SearchBar';


export default function BrowseMentors() {
   return (
      <div className='md:p-[40px] p-[16px] flex flex-col gap-[16px] md:bg-white bg-lightPurple'>
         <span className='text-[1.5rem] font-semibold md:block hidden'>Mentors</span>
         <div className='flex flex-col gap-[12px]'>
            <SearchBar />
            <div className='sort-mentors-section  flex flex-row gap-[16px]'>
               <h4 className='sort-mentors-text whitespace-nowrap'>
                  Sort Mentors by
               </h4>
               <div className='flex flex-row gap-[16px] overflow-x-auto no-scrollbar'>
                  <button className='sort-by-option whitespace-nowrap'>top rated</button>
                  <button className='sort-by-option whitespace-nowrap'>new</button>
                  <button className='sort-by-option whitespace-nowrap'>recommended</button>
               </div>
            </div>
            <div className='flex flex-row gap-[16px] overflow-x-auto no-scrollbar'>

               <button className='flex flex-row  items-center justify-center gap-[8px] bg-[#FBFBFB] text-[#91909B]  p-[12px] rounded-[15px] '>
                  <img src={allSvg} alt="domain-filter-icon" className='' />
                  <p className='whitespace-nowrap'>All</p>
               </button>

               <button className='flex flex-row  items-center justify-center gap-[8px] bg-[#FBFBFB] text-[#91909B]  p-[12px] rounded-[15px] '>
                  <img src={uiUx} alt="domain-filter-icon" />
                  <p className='whitespace-nowrap'>UI UX Design</p>
               </button>

               <button className='flex flex-row  items-center justify-center gap-[8px] bg-[#FBFBFB] text-[#91909B]  p-[12px] rounded-[15px] '>
                  <img src={marketing} alt="domain-filter-icon" />
                  <p className='whitespace-nowrap'>Marketing</p>
               </button>

               <button className='flex flex-row  items-center justify-center gap-[8px] bg-[#FBFBFB] text-[#91909B]  p-[12px] rounded-[15px] '>
                  <img src={webDev} alt="domain-filter-icon" />
                  <p className='whitespace-nowrap'>Web Development</p>
               </button>

               <button className='flex flex-row  items-center justify-center gap-[8px] bg-[#FBFBFB] text-[#91909B]  p-[12px] rounded-[15px] '>
                  <img src={aiML} alt="domain-filter-icon" />
                  <p className='whitespace-nowrap'>AI ML</p>
               </button>

               <button className='flex flex-row  items-center justify-center gap-[8px] bg-[#FBFBFB] text-[#91909B]  p-[12px] rounded-[15px] '>
                  <img src={seo} alt="domain-filter-icon" />
                  <p className='whitespace-nowrap'>SEO</p>
               </button>

               <button className='flex flex-row  items-center justify-center gap-[8px] bg-[#FBFBFB] text-[#91909B] p-[12px] rounded-[15px] '>
                  <img src={graphicDesign} alt="domain-filter-icon" />
                  <p className='whitespace-nowrap'>Graphic Design</p>
               </button>
            </div>
         </div>

         <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2  grid-cols-1 gap-[16px] w-full">
            <MentorCardsContainer />
         </div>
         <button className='view-more-mentors-btn'>View More Mentors</button>
      </div>
   )
}