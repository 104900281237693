import {  useContext, useState } from "react";
import MentorStepperProfile from "./MentorStepperProfile";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import MentorStepperBio from "./MentorStepperBio";
import { useNavigate } from "react-router-dom";
import { MentorImage } from "./MentorStepperProfile";
import {
  validateAdditionalDatas,
  validateAdditionDatasArray,
  validateCountryState,
  validateDOBFormat,
  validateFirstName,
  ValidatePhonenumber,
} from "../../utils/stepperValidation/menteeValidation";
import axios from "axios";
import Cookies from "js-cookie";
import toast,{ Toaster } from 'react-hot-toast';
import { Player } from "@lottiefiles/react-lottie-player";
import { AuthContext } from "../../contexts/AuthContext";

export interface formStateType {
  first_name: string;
  last_name: string;
  country: string;
  is_email_verified: Boolean;
  phone_number: string;
  languages: string[];
  gender: "";
  date_of_birth: string;
  city: string;
  profile_picture_url: string;
  areas_of_expertise: string[];
  description: string;
  role: string;
  organization: string;
  is_experience: Boolean;
  number_of_likes: 0;
  MentorExperience: number;
}

export interface MentorFormError {
  first_name: string[];
  phone_number: string[];
  date_of_birth: string[];
  country: string[];
  city: string[];
  role: string[];
  organization: string[];
  description: string[];
  areas_of_expertise: string[];
  languages: string[];
}

export default function MentorStepper() {
  const navigate = useNavigate();
  const token = Cookies.get('GB_ACCESS_TOKEN')
  const [loading, setLoading] = useState<boolean>(false)
  const steps = [
    "Complete Your Profile Details",
    "Complete Your Education Details",
  ];
  const [selectedImage, setSelectedImage] = useState<any>(MentorImage[0]);

  const [formError, setFormError] = useState<MentorFormError>({
    first_name: [],
    phone_number: [],
    date_of_birth: [],
    country: [],
    city: [],
    role: [],
    organization: [],
    description: [],
    areas_of_expertise: [],
    languages: [],
  });

  const [formState, setFormState] = useState<formStateType>({
    first_name: "",
    last_name: "",
    country: "",
    is_email_verified: false,
    phone_number: "",
    languages: [],
    gender: "",
    date_of_birth: "",
    city: "",
    profile_picture_url: selectedImage.url,
    areas_of_expertise: [],
    description: "",
    role: "",
    organization: "",
    is_experience: true,
    number_of_likes: 0,
    MentorExperience: 0,
  });

  const { authed, setAuthed} = useContext(AuthContext);

  const handleProfileError = () => {

    const { first_name, phone_number, date_of_birth, country, city } = formState;
    const usernameValidation = validateFirstName(first_name) as object;
    const phoneValidation = ValidatePhonenumber(phone_number);
    const dobValidation = validateDOBFormat(date_of_birth);
    const countryValidation = validateCountryState(country, "country");
    const stateValidation = validateCountryState(city, "city");

    const allValidation = [
      usernameValidation,
      phoneValidation,
      countryValidation,
      stateValidation,
      dobValidation,
    ];
    let isFormValid = true;
    allValidation.forEach((validation) => {
      if ((validation as { error: boolean }).error) {
        isFormValid = false;
        handleFormError(
          (validation as { key: keyof typeof formError }).key,
          (validation as { bucket: string[] }).bucket
        );
      } else {
        handleFormError(
          (validation as { key: keyof typeof formError }).key,
          []
        );
      }
    });
    return isFormValid;
  };

  const handleBioError = () => {
    const { role, organization, description, areas_of_expertise, languages } =
      formState;
    const roleValidation = validateAdditionalDatas(role, "role");
    const organizationValidation = validateAdditionalDatas(
      organization,
      "organization"
    );
    const descriptionValidation = validateAdditionalDatas(
      description,
      "description"
    );
    const areas_of_expertiseValidation = validateAdditionDatasArray(
      areas_of_expertise,
      "areas_of_expertise"
    );
    const languagesValidation = validateAdditionDatasArray(
      languages,
      "languages"
    );
    const allValidation = [
      roleValidation,
      organizationValidation,
      descriptionValidation,
      areas_of_expertiseValidation,
      languagesValidation,
    ];
    let isFormValid = true;
    allValidation.forEach((validation) => {
      if ((validation as { error: boolean }).error) {
        isFormValid = false;
        handleFormError(
          (validation as { key: keyof typeof formError }).key,
          (validation as { bucket: string[] }).bucket
        );
      } else {
        handleFormError(
          (validation as { key: keyof typeof formError }).key,
          []
        );
      }
    });
    return isFormValid;
  };

  const handleFormError = (key: keyof typeof formError, value: string[]) => {
    setFormError((prevStates) => {
      return {
        ...prevStates,
        [key]: value,
      };
    });
  };

  const handleOptionClick = (key: keyof typeof formState, value: string) => {
    if((key === "areas_of_expertise" || key === "languages" ) && formState[key].includes(value)){
      return;
    }
    setFormState((prevStates) => {
      return {
        ...prevStates,
        [key]: [...(formState[key] as string[]),value],
      };
    });
  }

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    key: keyof typeof formState,
    value: string,
    resetInputFiledAndRecommendation: Function,
  ) => {
    if (event.key === "Enter" && value !== "") {
      if((key === "areas_of_expertise" || key === "languages" ) && formState[key].includes(value)){
        event.currentTarget.value = "";
        resetInputFiledAndRecommendation();
        return;
      }
      const newInputValues = {
        ...formState,
        [key]: [...(formState[key] as string[]), value.trim()],
      };
      setFormState(newInputValues);
      event.currentTarget.value = "";
      resetInputFiledAndRecommendation();
    }
  };

  const handleMultiInputDelete = (
    key: keyof typeof formState,
    index: number
  ) => {
    setFormState((prevStates: any) => {
      return {
        ...prevStates,
        [key]: [
          ...prevStates[key].slice(0, index),
          ...prevStates[key].slice(index + 1),
        ],
      };
    });
  };

  const [step, setStep] = useState<number>(1);
  const mentorTopic = [
    "Let's Make your Mentor profile",
    "Let's make your chances of mentorships high",
    "Hey! All Done.",
  ];

  const handleStepper = (changeStep: number) => {
    if (step === 1 && changeStep === 1 && !handleProfileError()) {
      return;
    } else {
      setFormError((previousState) => {
        return {
          first_name: [],
          phone_number: [],
          date_of_birth: [],
          country: [],
          city: [],
          role: [],
          organization: [],
          description: [],
          areas_of_expertise: [],
          languages: [],
        };
      });
      setStep(step + changeStep);
    }
  };


  const updateFormState = (key: keyof typeof formState, value: string) => {
    setFormState((prevState) => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  };


  const handleSelectedImage = async(image: any) => {
    if (image === selectedImage.key) {
      setSelectedImage(MentorImage[0])
      updateFormState('profile_picture_url', MentorImage[0].url)
    } else {
      setSelectedImage(MentorImage.find((img) => img.key === image));
      updateFormState('profile_picture_url', (MentorImage.find((img) => img.key === image) as {url:string})?.url);
    }
  }


  const handleSubmit =async () => {
    const isProfileValid = handleProfileError();
    const isBioValid = handleBioError();
    if (isProfileValid && isBioValid) {
      const {description:bio,role:designation,organization:company, is_email_verified, ...rest } = formState
      setLoading(true)
      try {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const response = await axios.post(`${process.env.REACT_APP_GROWBINAR_BACKEND_URL}/mentor_details/`, {
          ...rest,
          bio,
          designation,
          company,
        }, {
          headers: {
            "Authorization": `Bearer ${token}`
          }
        })
        setLoading(false)
        setAuthed(()=>{
          return {
            ...authed,
            status: 200,
          }
        })
        toast.success('Profile Created Successfully')
        setTimeout(() => {
          navigate('/mentor/home')
        }, 2000)
      } catch (err: any) {
        setLoading(false)
        toast.error(err.response.data.message)
      }
    }
  };

  return (
    <>
      <div className=" pb-[12px] h-full">
        <Toaster/>
        {
          loading && (
            <div className="h-screen flex items-center justify-center">
              <Player
                src='https://lottie.host/41557a43-02dc-49a2-a398-a898f8e60be3/IshXgmtJEG.json'
                className="player"
                loop
                autoplay
              />
            </div>
          )
        }
        <div className={`${loading === false ? 'flex' : 'hidden'} w-[90%] mx-auto  flex-col gap-[16px]`}>
          <div>
            <h1 className="text-txtPurple font-bold text-[40px]">
              {mentorTopic[step - 1]}
            </h1>
          </div>
          <div className="w-full rounded-xl bg-white opacity-90 z-50 py-[20px] relative">
            {step === 1 ? (
              <></>
            ) : (
              <button
                className=" flex items-center justify-center rounded-full absolute md:left-[2%] left-[.5%] top-[50%]"
                onClick={() => {
                  handleStepper(-1);
                }}
              >
                {" "}
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 25 41"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22 3L3 20.5L22 38"
                    stroke="url(#paint0_linear_1_2605)"
                    stroke-width="6"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_1_2605"
                      x1="12.5"
                      y1="13"
                      x2="20.6948"
                      y2="38.4207"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#6C63FF" />
                      <stop offset="1" stop-color="#008CFF" />
                    </linearGradient>
                  </defs>
                </svg>
              </button>
            )}

            {step < 2 ? (
              <button
                className="z-50 rounded-full absolute right-[2%] top-[50%]"
                onClick={() => {
                  handleStepper(1);
                }}
              >
                {" "}
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 25 41"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3 3L22 20.5L3 38"
                    stroke="url(#paint0_linear_1_2602)"
                    stroke-width="6"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_1_2602"
                      x1="12.5"
                      y1="13"
                      x2="4.30516"
                      y2="38.4207"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#6C63FF" />
                      <stop offset="1" stop-color="#008CFF" />
                    </linearGradient>
                  </defs>
                </svg>
              </button>
            ) : (
              <button
                className="z-50 rounded-full absolute right-[2%] top-[50%]"
                onClick={handleSubmit}
              >
                {" "}
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 43 31"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3 15.8526L15.0905 28L40 3"
                    stroke="url(#paint0_linear_1_2755)"
                    stroke-width="5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_1_2755"
                      x1="-5"
                      y1="13"
                      x2="40"
                      y2="16"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#6C63FF" />
                      <stop offset="1" stop-color="#008CFF" />
                    </linearGradient>
                  </defs>
                </svg>
              </button>
            )}
            <div className="w-[80%] mx-auto flex flex-col gap-[20px]">
              <div>
                <div>
                  <Box sx={{ width: "100%" }}>
                    <Stepper activeStep={step - 1} alternativeLabel>
                      {steps.map((label) => (
                        <Step key={label}>
                          <StepLabel>{label}</StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </Box>
                </div>
              </div>
              <div className="min-h-[480px]">
                <div className="grid md:grid-cols-2 grid-cols-1 md:gap-x-[28px] gap-y-[16px]">
                  {step === 1 ? (
                    <MentorStepperProfile
                      formState={formState}
                      updateFormState={updateFormState}
                      formError={formError}
                      handleSelectedImage={handleSelectedImage}
                      selectedImage={selectedImage}
                    />
                  ) : step === 2 ? (
                    <MentorStepperBio
                      handleOptionClick= {handleOptionClick}
                      formState={formState}
                      updateFormState={updateFormState}
                      handleKeyDown={handleKeyDown}
                      handleMultiInputDelete={handleMultiInputDelete}
                      formError={formError}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
