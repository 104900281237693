import {  useNavigate } from "react-router-dom"
import hostedImageUrls from "../../utils/hosted_image";


export default function Role(){
    const navigate = useNavigate();
    const handleSubmit = (role: string) => {
        localStorage.setItem("userRole", role)
        navigate(`/auth/${role}`)
    }
    const data = [
      {
        image: hostedImageUrls.signup_mentor,
        text: "Sign up as a Mentor",
        buttonText: "Mentor SignUp",
        role: "mentor",
      },
      {
        image: hostedImageUrls.signup_mentee,
        text: "Sign up as a Mentee",
        buttonText: "Mentee SignUp",
        role: "mentee",
      },
    ];
    return (
        <div className="flex w-full min-h-screen justify-center items-center">
            <div className="flex flex-col md:flex-row gap-8">
                {
                    data.map((item, index) => {
                        return (
                            <div key={index} className="bg-slate-50 w-[420px] border boder-[#c0c0c0] p-8 flex flex-col gap-4 rounded-md">
                                <img src={item.image} alt="signUpMentor" />
                                <div className="w-full text-center text-xl font-CircularStdMedium">{item.text}</div>
                                <button onClick={()=>handleSubmit(item.role)}
                                    className="w-full text-center p-2 bg-txtPurple hover:bg-hoverpurple text-white hover:text-[#333] text-xl font-CircularStdMedium duration-200 ease-in-out rounded-md"
                                >
                                    {item.buttonText}
                                </button >
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}