import "./index.css";

interface MentorBackgroundDetailsCardProps {
  education?: string | string[];
  experience?: string | string[];
  fluency?: string | string[];
}
export default function MentorBackgroundDetailsCard(
  props: MentorBackgroundDetailsCardProps
) {
  return (
    <div className="flex flex-col gap-[16px]">
      <div className="flex gap-[16px]">
        <div className="min-w-[100px]">
          <span className="text-[1.2rem] text-gray-600 font-medium">Education</span>
        </div>
        <div className="flex flex-row flex-nowrap overflow-y-auto no-scrollbar gap-[16px]">
          {typeof props?.education === "string" ? (
            <p className="text-[1rem] px-[8px] bg-red-400 text-white rounded-[8px]">Education</p>
          ) : Array.isArray(props?.education) ? (
            <>
              {props?.education.map((item, index) => (
                <span key={index} className="text-[1rem] bg-red-400 text-white p-[8px] rounded-[8px]  ">
                  {item}
                </span>
              ))}
            </>
          ) : (
            <span>Invalid education data</span>
          )}
        </div>
      </div>
      <div className="flex gap-[16px]">
        <div className="min-w-[100px]">
          <span className="text-[1.2rem] text-gray-600 font-medium">Expertise</span>
        </div>
        <div className="flex flex-row flex-nowrap whitespace-nowrap overflow-y-auto no-scrollbar gap-[16px]">
          {typeof props?.experience === "string" ? (
            <span className="text-[1rem] px-[8px] text-[#4943ad] bg-[#b9b5f9] rounded-[8px]">{props?.experience}</span>
          ) : Array.isArray(props?.experience) ? (
            <>
              {props?.experience.map((item, index) => (
                <span key={index} className="text-[1rem] px-[8px] text-[#4943ad] bg-[#b9b5f9] rounded-[8px]">
                  {item}
                </span>
              ))}
            </>
          ) : (
            <span>Invalid experience data</span>
          )}
        </div>
      </div>
      <div className="flex gap-[16px]">
        <div className="min-w-[100px]">
          <span className="text-[1.2rem] text-gray-600 font-medium">Fluency</span>
        </div>
        <div className="flex flex-row flex-nowrap overflow-y-auto no-scrollbar gap-[16px]">
          {typeof props?.fluency === "string" ? (
            <span className="text=[#3f3d4d] bg-[#fbfbfe] rounded-[8px] px-[16px]  border border-[#d1d4d6]">{props?.fluency}</span>
          ) : Array.isArray(props?.fluency) ? (
            <>
              {props?.fluency.map((item, index) => (
                <span key={index} className="text=[#3f3d4d] bg-[#fbfbfe] px-[16px] rounded-[8px] border border-[#d1d4d6]">
                  {item}
                </span>
              ))}
            </>
          ) : (
            <span>Invalid fluency data</span>
          )}
        </div>
      </div>
    </div>
  );
}
