import { Player } from '@lottiefiles/react-lottie-player';  // import the Player component from the lottiefiles library
import React from 'react';

interface LoaderProps {
    srcvideo?: string;
}


const Loader : React.FC <LoaderProps> = ({srcvideo}) =>{
    const defaultSrc = 'https://lottie.host/d5eafc44-43c8-4920-8fc3-163a7ed2c843/UhZfFe6slC.json';

    return(
        <> 
            <div className="h-screen flex items-center justify-center">
                <Player
                    src={srcvideo || defaultSrc}
                    className="player"
                    loop
                    autoplay
                />
            </div>
        </>
    )
}

export default Loader;