import { useEffect, useState } from 'react'
import { getCookie } from '../../utils/cookies/cookieCurd'
import Cards from './Cards'
import axios from "axios"
import ZeroMessages from "../../utils/ZeroMessage/zeroMessage.json"
import ZeroData from '../../components/ZeroData'
import SingleCardLoader from '../../components/SingleCardLoader'


export default function MentorUpcomingContainer() {
    const [mentorSessions, setMentorSessions] = useState<any | null>(null);

    useEffect(() => {
        fetchSessionsMentor()
    }, [])

    const fetchSessionsMentor = async () => {

        // const 
        const token = getCookie('GB_ACCESS_TOKEN')


        try {
            const response = await axios.get(`${process.env.REACT_APP_GROWBINAR_BACKEND_URL}/sessions/upcoming_sessions/`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            const data = response.data.data

            setMentorSessions(() => {
                return data.filter((session: any) => session.status === "Upcoming")
            }
            )
        } catch (err: any) {
            console.log(err)
        }
    }

    if(mentorSessions === null) {
        return(
            <>
                <div className='flex'>
                    <SingleCardLoader />
                </div>
            </>
        )
    }

    return (
        <div>
            <div className='flex flex-row gap-2 overflow-x-auto no-scrollbar'>
                {
                    mentorSessions && mentorSessions.length !== 0 ?
                        mentorSessions.map((session: any, index: number) => (
                            <div className='w-[347px] flex-shrink-0 border-[1px] bg-gray-100 shadow-sm rounded-[16px] hover:border-gbpurple p-[16px]' key={index}>
                                <Cards sessionDetails={session} name={session.mentee || session.name} profileImage={session.profile_pic} title={session.reason} mentor={session.name} timing={session.timing} meet_link={session.meet_url} designation = {session.role} organisation={session.organisation} />
                            </div>
                        ))
                        :
                        <>
                            <ZeroData one={ZeroMessages["Upcoming"].one} two={ZeroMessages["Upcoming"].two} />
                        </>
                }
            </div>
        </div>
    )
}
