import "./index.css"
import clock from "../../assets/images/clock.svg"
import { useEffect, useState } from "react"
import { getCookie } from "../../utils/cookies/cookieCurd"
import axios from "axios"
import toast, {Toaster} from "react-hot-toast"
import Spinner from "../../components/Spinner/indext"
import { convertToAMPM } from "../../utils/HelperFunctions";
import ZeroData from "../../components/ZeroData"
import ZeroMessages from "../../utils/ZeroMessage/zeroMessage.json"
import JoinBooking from "../../components/JoinSession"
import ThreedotLoader from "../../components/ThreedotLoader"
import { useContext } from "react"
import { AuthContext } from "../../contexts/AuthContext"

const token = getCookie('GB_ACCESS_TOKEN')


interface MenteeSessionCancelProps {
    session_id:number | null; 
    setSessionCancelPopUp:Function;
    filterRemoval:Function;
    tabType:string;
}

export const MenteeSessionCancel : React.FC<MenteeSessionCancelProps> = ({session_id, setSessionCancelPopUp, filterRemoval, tabType}) =>{
    const [spinner , setSpinner] = useState(false)
    const {setSessionCount} = useContext(AuthContext)
    const handleCancelSession =async () =>{
        try{
            setSpinner(true)
            const response = await axios.post(`${process.env.REACT_APP_GROWBINAR_BACKEND_URL}/sessions/cancel_sessions/`,{
                    session_id:session_id,
            },{
                headers:{
                    'Authorization':`Bearer ${token}`
                }
            })
            setSpinner(false)
            setSessionCancelPopUp(false)
            filterRemoval("deletion",session_id)
            if(tabType === "Upcoming"){
                setSessionCount((prev:any)=>{
                    return(
                        {
                            ...prev,
                            upcommingCount:prev.upcommingCount - 1
                        }
                    )
                })
            }
            else if(tabType === "Pending Request"){
                setSessionCount((prev:any)=>{
                    return(
                        {
                            ...prev,
                            pendingCount:prev.pendingCount - 1
                        }
                    )
                })
            }
            toast.success(response.data.message)

        }catch(err: any){
            setSpinner(false)
            toast.error(err.response.data.message)
        }   
    }

    return(
        <>
            <div className="fixed inset-0 bg-black flex items-center justify-center z-20 bg-opacity-80" onClick={()=>setSessionCancelPopUp(false)}>
                <div className=" bg-white rounded-xl p-[16px] flex flex-col gap-[16px]" onClick={(e) => e.stopPropagation()}>
                    <div>
                        <h1 className="text-xl font-medium">Do you want to cancel the request ?</h1>
                    </div>
                    <div className="flex gap-[16px] flex-row-reverse">
                        {
                            spinner === false ?
                                <button className="px-[16px] py-[8px] rounded-[8px] border-2 border-red-500 hover:border-red-400" onClick={handleCancelSession}>Yes</button>
                                :
                                <button className="border-2 border-red-500 hover:border-red-400 flex items-center justify-center px-[16px] py-[8px] rounded-[8px]">
                                    <Spinner/>
                                </button>
                            }
                       
                        <button className="px-[16px] py-[8px] rounded-[8px] bg-red-500 hover:bg-red-400 text-white" onClick={()=>setSessionCancelPopUp(false)}>No</button>
                    </div>
                </div>
            </div>
        </>

    )
}

const MenteeBooking: React.FC = () => {
    const [tabType, changeBookingsTab] = useState<string>("Upcoming");
    const token = getCookie('GB_ACCESS_TOKEN')
    const [menteeSessions,setMenteeSessions] = useState<any | null>(null);
    const [filteredMenteeSessions, setFilteredMenteeSessions] = useState<any | null>(null);
    const [sessionCancelPopUp, setSessionCancelPopUp] = useState<boolean>(false);
    const [cancelSessionId, setCancelSessionId] = useState<number | null>(null);
    const [joinSession, setJoinSession] = useState<boolean>(false);
    const [startSessionData, setStartSessionData] = useState<any>(null);
    const [startedSessionId, setStartedSessionId] = useState<number | null>(null);
    const [popupMode, setPopupMode] = useState<string>("");

    const { setSessionCount } = useContext(AuthContext);

    useEffect(()=>{
        fetchSessionsMentee()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])


    const fetchSessionsMentee = async () =>{

        // const 

        try{
            const response = await axios.get(`${process.env.REACT_APP_GROWBINAR_BACKEND_URL}/sessions/upcoming_sessions_mentee/`,{
               
                headers:{
                    'Content-Type':'application/json',
                    'Authorization':`Bearer ${token}`
                }
            })
            const data = response.data.data
            setMenteeSessions( data )
            setFilteredMenteeSessions(()=>
            {
               return data.filter((session:any)=>session.status === "Upcoming")   
            }
        )
    
        }catch(err : any){
            console.log(err)
        }
    }

    const filterMenteeSessions = (state : string, session_id? : number | null) =>{
        
        if (state === "deletion" && session_id !== null){
            setMenteeSessions((prev: any) => {
                return prev.filter((session: any) => session.session_id !== session_id)
            })
            setFilteredMenteeSessions((prev : any)=>{
                return prev.filter((session: any) => session.session_id !== session_id)
            })
            return;
        }
        changeBookingsTab(state)
        setFilteredMenteeSessions(()=>
            {
               return menteeSessions.filter((session:any)=>session.status === state)
            }
        )
        
    }

    const handleSessionCancellation = (session_id:number) =>{
        setCancelSessionId(session_id)
        setSessionCancelPopUp(true)    
    }

    const handleStartSession = (session_id : number) => {
        setStartSessionData(() => filteredMenteeSessions?.find((sessionData : any) => sessionData.session_id === session_id));   
        setJoinSession(true)
    }

    if(!menteeSessions){
        return(
            <>
                <div className="flex justify-center items-center w-full fixed inset-0 top-[20%] right-[50%]">
                    <ThreedotLoader />
                </div>
            </>
        )
    }

    return (
        <div>
            <Toaster/>
            <div className="p-4">
                {
                    sessionCancelPopUp && (
                        <MenteeSessionCancel setSessionCancelPopUp={setSessionCancelPopUp} session_id={cancelSessionId} filterRemoval={filterMenteeSessions} tabType={tabType} />
                    )
                }
                {
                    joinSession && (
                        <JoinBooking setPopUp={setJoinSession} sessionDetails={startSessionData} setStartedSessionId = {setStartedSessionId} popupMode = {popupMode} />
                    )
                }
                <p className=" text-left text-[#263238] text-[32px] p-2 font-bold not-italic leading-9">
                    Discover Bookings
                </p>
                <div className="">
                    <p className="text-[#464646] text-left text-[18px] p-2 leading-9 font-medium not-italic">
                        The session timings are following your local timezone <span className="font-bold">Asia/Calcutta </span><span className="text-gbpurple">change</span></p>
                </div>

                {/*bookings tab -- 1:1 , completed, request pending */}
                <div className="flex justify-between sm:justify-start flex-row sm:gap-[25px]">
                    <button className={`p-2 text-[#130F26] text-[16px] text-center font-medium not-italic leading-9 ${tabType === 'Upcoming' ? 'border-black border-b-2 ' : 'border-transparent'} focus: translate-all`} onClick={() => { filterMenteeSessions('Upcoming') }}>1:1 Booking</button>
                    <button className={`p-2 text-[#130F26] text-[16px] text-center font-medium not-italic leading-9 ${tabType === 'Completed' ? 'border-black border-b-2 ' : 'border-transparent'} focus: translate-all`} onClick={() => { filterMenteeSessions('Completed') }}>Mentorship Logs</button>
                    <button className={`relative p-2 text-[#130F26] text-[16px] text-center font-medium not-italic leading-9 ${tabType === 'Pending Request' ? 'border-black border-b-2 ' : 'border-transparent'} focus: translate-all`} onClick={() => { filterMenteeSessions('Pending Request') }}>
                        <span className={`${menteeSessions.filter((sess : any, index : number) => sess.status === "Pending Request").length !== 0 ? "absolute" : "hidden"} top-2 -right-1 flex h-3 w-3`}>
                            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-txtPurple opacity-75"></span>
                            <span className="relative inline-flex rounded-full h-3 w-3 bg-txtPurple"></span>
                        </span>
                        Awaiting Approval
                    </button>
                </div>

                {/*mentee-profile-info */}

                {

                    menteeSessions && filteredMenteeSessions && filteredMenteeSessions.length !== 0 ?
                         filteredMenteeSessions.map((session: any, index : number) => 
                            {
                                return(
                                    <>  
                                        <div className={`bg-gray-100 lg:w-[800px] md:w-[700px] sm:[600px] w-full border-[0.5px] rounded-lg mt-[20px] flex-shrink-0 p-[8px] ${tabType === "Completed" ? "opacity-70 border border-gbpurple" : "opacity-100 border-[#D9D9D9]"}`} key={session.session_id}>
                                            <div className="flex flex-col details-with-main-container">

                                                <div className="flex  flex-shrink-0 gap-3 p-2 w-full">
                                                    <img className=" sm:h-[137px] h-[120px] flex-shrink-0 rounded" src={session.profile_pic} alt="mentee" />
                                                    <div className="flex justify-between flex-col sm:flex-row w-full">
                                                        <div className="flex flex-col gap-[12px] w-full">
                                                            <p className="text-[#455A64] text-[15px] font-medium leading-6 not-italic w-full">
                                                                Mentorship Session with <br></br>
                                                                <span className="text-txtPurple">{session.name}</span>
                                                            </p>
                                                            <p className="text-[#636B78] w-full text-[15px] font-medium leading-6 not-italic truncate">{session.role} <span className="text-[#A1A6AD]">at </span>{session?.organisation} </p>
                                                            <div className="inline-flex gap-2 items-center justify-normal mt-auto w-full">
                                                                <img className="h-[14px] w-[14px] flex-shrink-0" src={clock} alt="clock" />
                                                                <p className="text-[#464646] text-[13px] font-medium leading-5 not-italic">{session.date}</p>
                                                                <p className="text-[#464646] text-[13px] font-medium leading-5 not-italic">{convertToAMPM(session.time)}</p>
                                                            </div>
                                                            <div>
                                                                <div className={`border-txtGrey p-[12px] whitespace-nowrap border bg-[#FBFBFE]  rounded-[8px] inline-block`} >
                                                                    {session?.reason || session?.Reasons}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="flex gap-2 flex-row-reverse" >
                                                {
                                                    tabType === 'Upcoming' ?
                                                        (
                                                            <>
                                                            
                                                                    <button className="h-[50px] w-[205px] p-[10px] text-[#F9F9FA] bg-gbpurple hover:opacity-80 ease-in text-[18px] text-center font-medium not-italic leading-4 rounded-lg m-[10px]" onClick={() => { 
                                                                        handleStartSession(session.session_id)
                                                                        setPopupMode("join")
                                                                     } }>
                                                                        {
                                                                            (startedSessionId === session.session_id) ?("Rejoin") : ("Start")
                                                                        }
                                                                    </button>
                                                                
                                                                <button className="h-[50px] w-[205px] p-[10px] text-[#F6291C] bg-[#F9F9FA] hover:bg-gray-100 ease-in text-[18px] text-center font-medium not-italic leading-4 border border-[#D1D4D6] rounded-lg my-[10px]" onClick={()=>handleSessionCancellation(session.session_id)}>Cancel</button>
                                                            </>
                                                        )
                                                        :
                                                        tabType === 'Pending Request' ?
                                                            (
                                                                <>
                                                                    <button className="h-[50px] w-[205px] p-[10px] text-[#F6291C] bg-[#F9F9FA] hover:bg-gray-100 text-[18px] text-center font-medium not-italic leading-4 border border-[#D1D4D6] rounded-lg my-[10px]" onClick={()=>handleSessionCancellation(session.session_id)}>Cancel</button>
                                                                </>
                                                            )
                                                            :
                                                            ''
                                                }
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                        )
                    :
                        <ZeroData one={ZeroMessages[tabType as keyof typeof ZeroMessages].one} two={ZeroMessages[tabType as keyof typeof ZeroMessages].two}/>
                }
            </div>
        </div>
    );
}

export default MenteeBooking;