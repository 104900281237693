import { useEffect, useState } from 'react';
import { formStateType, MentorFormError } from '../MentorStepper';
import ValidationError from '../../components/ValidationError';
import './index.css'
import { MdClose } from "react-icons/md";



interface MentorBioProps {
    formState: formStateType;
    updateFormState: Function;
    handleKeyDown: Function;
    handleMultiInputDelete: Function;
    formError : MentorFormError;
    handleOptionClick: Function;
}

interface AutoCompleteInputProps {
    id: any;
    label: string;
    formError: MentorFormError;
    options: string[];
    handleOptionClick: Function;
    formState: formStateType;
    handleKeyDown: Function;
    handleMultiInputDelete: Function;
    placeholder?: string;
}

const AutoCompleteInput = ({
    id,
    label,
    formError,
    options,
    formState,
    handleKeyDown,
    handleOptionClick,
    handleMultiInputDelete,
    placeholder
  }: AutoCompleteInputProps) => {
    const [userInputValue, setUserInputValue] = useState<string>("");
    const [filteredData, setFilteredData] = useState<string[]>([]);
    useEffect(()=>{
        if(userInputValue.length > 0){
            setFilteredData(options.filter((item) => item.toLowerCase().includes(userInputValue.toLowerCase())))
        }
        else{
            setFilteredData([])
        }
    },[userInputValue, options])
    return (
      <>
          <label htmlFor="area_of_expertise" className='text-xl font-CircularStdMedium'>{label}</label>
         <input
            id="areas_of_expertise"
            className={`bg-white !opacity-100 md:w-[90%] w-full rounded-[32px] 
            shadow-md p-[12px] outline-2  outline-gray-100  focus:outline-txtPurple duration-200 ease-in ${formError.areas_of_expertise.length > 0 ? '!border-2 border-red-500 shake' : ''}`}
            placeholder={placeholder}
            value={userInputValue}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUserInputValue(e.target.value)}
            onKeyDown={(event) => handleKeyDown(event, id, (filteredData.length)?
                filteredData[0]: "others", ()=>{
                    setUserInputValue('')
                    setFilteredData([])
                })}
        // onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFormState('areas_of_expertise', e.target.value)}
         />
        <div className='md:w-[86%] w-full'>
            <ValidationError errors={formError[id as keyof MentorFormError]} />
        </div>
        <div className="w-full relative ">
                <div className= "absolute md:w-[90%] w-full  max-h-[150px] overflow-y-auto shadow-md  overflow-hidden">
                {(filteredData.length > 0)?
                    filteredData?.map((item, index) => {
                        return (
                            <button key={index} className={`w-full flex justify-start p-2 border-b border-gray-200 hover:bg-gray-200 hover:shadow-md cursor-pointer ${(index === 0)? 'bg-blue-100':"bg-white"}`}
                                onClick={(e) => {
                                    handleOptionClick(id, item);
                                    setFilteredData([])
                                    setUserInputValue('')
                                }}
                            >
                                <span>{item}</span>
                            </button>
                        )
                    }): (userInputValue.length)? <>
                        <button className="w-full flex justify-start p-2 border-b border-gray-200 hover:bg-gray-200 hover:shadow-md cursor-pointer bg-white"
                            onClick={(e) => {
                                handleOptionClick(id, "others");
                                setFilteredData([])
                                setUserInputValue('')
                            }} 
                        >
                            <span>Others</span>
                        </button>
                    </> : <></>
                }
            </div>
        </div>
        <div className="flex flex-row gap-[8px] overflow-x-auto pb-2 scroll-width">
            {
                ( id === "areas_of_expertise")?
                    formState["areas_of_expertise"].map((item, index) => {
                        return (
                            <div key={index} className='flex flex-row items-center gap-[16px] bg-txtPurple text-white p-[12px] rounded-[32px] shadow-sm mr-[8px] whitespace-nowrap'>
                                <span>{item}</span>
                                <button className="text-white" onClick={(e) => handleMultiInputDelete('areas_of_expertise', index)}>
                                    <MdClose/>
                                </button>
                            </div>
                        )
                    }):(
                        <>
                            {formState["languages"].map((item, index) => {
                                return (
                                    <div key={index} className='flex flex-row items-center gap-[16px] bg-txtPurple text-white p-[12px] rounded-[32px] shadow-sm mr-[8px] whitespace-nowrap'>
                                        <span>{item}</span>
                                        <button className="text-white" onClick={(e) => handleMultiInputDelete('languages', index)}>
                                            X
                                        </button>
                                    </div>
                                )
                            })}
                        </>
                    )
            }
        </div>
        
      </>
    );
  };

export default function MentorStepperBio({formState, updateFormState, handleKeyDown, handleMultiInputDelete, formError, handleOptionClick }: MentorBioProps){

    const [intrestedAreaOption] = useState<string[]>([
        "Web Development",
        "Mobile Development",
        "Game Development",
        "Software Design",
        "Data Science",
        "Machine Learning",
        "Artificial Intelligence",
        "Cybersecurity",
        "Cloud Computing",
        "DevOps",
        "Database Management",
        "Embedded Systems",
        "Internet of Things (IoT)",
        "Network Engineering",
        "Blockchain Technology",
        "Augmented Reality (AR)",
        "Virtual Reality (VR)",
        "Robotics",
        "UI/UX Design",
        "Project Management",
        "Quality Assurance (QA)",
        "Automation",
        "Big Data",
        "Business Intelligence",
        "Natural Language Processing (NLP)",
        "Computer Vision",
        "Quantum Computing"
    ]);
    const [languagesOption] = useState<string[]>([
        "Hindi",
        "English",
        "Bengali",
        "Telugu",
        "Marathi",
        "Tamil",
        "Gujarati",
        "Kannada",
        "Odia",
        "Malayalam",
        "Punjabi",
        "Assamese",
        "Maithili",
        "Bhili/Bhilodi",
        "Santali"
    ]);
    
    return(
        <>
            

                        <div className='flex md:justify-between md:flex-row flex-col md:gap-0 gap-y-[16px]'>
                            <div className='md:w-[25%] w-full flex flex-col gap-[8px]'>
                                <label htmlFor='' className='text-xl font-CircularStdMedium'>Experience</label>
                    <select className='outline-2  outline-gray-100  focus:outline-txtPurple duration-200 ease-in bg-white rounded-[32px] shadow-md p-[12px]' onChange={(e) => updateFormState('MentorExperience', e.target.value)} value={formState.MentorExperience as string | number}>
                    { 
                        [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30].map((item,index)=>{
                            return(
                            <option className='' value={item} key={index}>{item} {item >= 2 ? "years" : "year"}</option>
                            )
                        })
                    }
                    </select>
                </div>
                <div className='flex md:w-[70%] w-full flex-col gap-[8px]'>
                    <label htmlFor="role" className='text-xl font-CircularStdMedium'>Designation</label>
                    <input 
                        id="role"
                        className={`bg-white !opacity-100 md:w-[90%] w-full outline-2  outline-gray-100  focus:outline-txtPurple duration-200 ease-in rounded-[32px] shadow-md p-[12px] ${formError.role.length > 0 ? 'border-2 border-red-500 shake' : ''}`}
                        placeholder='Software Engineer, ML Engineer ...'
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFormState('role', e.target.value)} value={formState.role as string}
                    />
                    <div className='md:w-[90%] w-full'>
                        <ValidationError errors={formError["role"]} />
                    </div>
                </div>
            </div>
            <div>
                {/* Organization input field */}

                <div className='flex flex-col gap-[8px]'>
                    <label htmlFor="description" className='text-xl font-CircularStdMedium'>Organization</label>
                    <input
                        id="description"
                        className={`bg-white !opacity-100 md:w-[90%] w-full outline-2  outline-gray-100  focus:outline-txtPurple duration-200 ease-in rounded-[32px] shadow-md p-[12px] ${formError.organization.length > 0 ? 'border-2 border-red-500 shake' : ''}`}
                        placeholder='Amazon , Google ...'
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFormState('organization', e.target.value)} value={formState.organization as string}
                         />
                    <div className='md:w-[90%] w-full'>
                        <ValidationError errors={formError["organization"]} />
                    </div>
                </div>
            </div>

                {/* Area of Interest input field */}

                <div className='flex flex-col gap-[8px]'>
                    <AutoCompleteInput
                        id={"areas_of_expertise"}
                        label="Area of Interest"
                        formError={formError}
                        options={intrestedAreaOption}
                        formState={formState}
                        handleKeyDown={handleKeyDown}
                        handleOptionClick={handleOptionClick}
                        handleMultiInputDelete={handleMultiInputDelete}
                        placeholder='Web Development, Data Science ...'
                    />
                </div>


                {/* Language input field */}
                <div className='flex flex-col gap-[8px]'>
                    <AutoCompleteInput
                        id={"languages"}
                        label="Languages Known"
                        formError={formError}
                        options={languagesOption}
                        formState={formState}
                        handleKeyDown={handleKeyDown}
                        handleOptionClick={handleOptionClick}
                        handleMultiInputDelete={handleMultiInputDelete}
                        placeholder='English, Tamil, Hindi ...'
                    />
                </div>

                 {/* current study input field */}

                <div>
                </div>

                {/* Description input field */} 


                <div className='flex flex-col gap-[8px]'>
                    <label htmlFor="description" className='text-xl font-CircularStdMedium'>Great! What does describe you the best ?</label>
                    <textarea 
                        id="description"
                    className={`bg-white !opacity-100 md:w-[90%] w-full outline-2  outline-gray-100  focus:outline-txtPurple duration-200 ease-in rounded-[32px] shadow-md p-[12px] ${formError.description.length > 0 ? 'border-2 border-red-500 shake' : ''}`}
                        placeholder='Tell us more about yourself, your goals and what you love..'
                        onChange={(e) => updateFormState('description', e.target.value)} value={formState.description as string} />
                    <div className='w-[90%]'>
                        <ValidationError errors={formError["description"]} />
                    </div>
                </div>
        </>
    )
}